import React from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.scss"
import ArrowRight from "../../icons/arrow-right"
import AnonPerson from "../../icons/anon-person"

const VacanciesSimpleItem = ({ item }) => {
    return (
        <Link to={item.slug} className={styles.item}>
            <div className={styles.image}>
                <AnonPerson />
            </div>
            <div className={styles.content}>
                <h4
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <span
                    className={styles.position}
                    dangerouslySetInnerHTML={{ __html: item.employment }}
                />
                <span className={styles.more}>
                    Bekijk vacature <ArrowRight />
                </span>
            </div>
        </Link>
    )
}

export default VacanciesSimpleItem
