import React, { Component } from "react"

import styles from "./styles.module.scss"
import Cross from "../../icons/cross"

class CookieNotice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
        }
    }

    setCookie() {
        if (window.localStorage.getItem("acceptedCookie") === null) {
            window.localStorage.setItem("acceptedCookie", "false")
            this.setState({ show: false })
            this.setPadding()
        }
    }

    hide = () => {
        window.localStorage.setItem("acceptedCookie", "true")
        this.setState({ show: false })
        this.setPadding()
    }

    componentDidMount() {
        this.setCookie()

        if (window.localStorage.getItem("acceptedCookie") === "true") {
            this.setState({ show: false })
        } else {
            this.setState({ show: true })
        }
        this.setPadding()
        window.addEventListener("resize", this.setPadding)
    }

    setPadding = () => {
        setTimeout(() => {
            if (window.innerWidth < 768) {
                if (this.refs.cookie && this.state.show === true) {
                    document.body.style.paddingBottom = `${
                        this.refs.cookie.clientHeight + 55
                    }px`
                } else {
                    document.body.style.paddingBottom = `${55}px`
                    this.setState({ show: false })
                }
            } else {
                if (this.refs.cookie && this.state.show === true) {
                    document.body.style.paddingBottom = `${this.refs.cookie.clientHeight}px`
                } else {
                    document.body.style.paddingBottom = `0px`
                    this.setState({ show: false })
                }
            }
        }, 100)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setPadding, false)
    }

    render() {
        const show = this.state.show

        return show === true ? (
            <div className={styles["cookie-notice"]} ref="cookie">
                <span className={styles.close} onClick={this.hide}>
                    <Cross />
                </span>
                <div className={styles.wrapper}>
                    <h4 className={styles.title}>
                        Career Rebels maakt gebruik van cookies
                    </h4>
                    <p className={styles.description}>
                        Om je beter en persoonlijker te helpen, gebruiken wij
                        cookies en vergelijkbare technieken. Met de cookies
                        volgen wij en derde partijen jouw internetgedrag binnen
                        onze site. Hiermee tonen we advertenties op basis van
                        jouw interesse en kun je informatie delen via social
                        media. Als je verder gaat op onze website gaan we ervan
                        uit dat je dat goed vindt. Meer weten? Check ons
                        cookiebeleid.
                    </p>
                </div>
            </div>
        ) : null
    }
}
export default CookieNotice
