/* eslint no-eval: 0 */
import React from "react"
import gtmParts from "react-google-tag-manager"

// eslint-disable-next-line
String.prototype.toDOM = () => {
    let d = document,
        i,
        a = d.createElement("div"),
        b = d.createDocumentFragment()
    a.innerHTML = this
    while ((i = a.firstChild)) b.appendChild(i)
    return b
}

class GoogleTagManager extends React.Component {
    componentDidMount() {
        const dataLayerName = this.props.dataLayerName || "dataLayer"

        const gtm = gtmParts({
            id: this.props.gtmId,
            additionalEvents: {
                sourcegroup: this.props.gtmGroupname,
                sourceid: this.props.gtmSource,
                age: this.props.age,
                mtongue: this.props.gtmMtongue,
                city: this.props.city,
            },
        })

        const scriptContent = gtm.scriptAsReact()
        const noScriptContent = gtm.noScriptAsReact()

        if (!window[dataLayerName]) {
            const script = document.createElement("script")
            const iframe = document.createElement("iframe")
            const noScript = document.createElement("noscript")

            const scriptText = document.createTextNode(
                scriptContent.props.dangerouslySetInnerHTML.__html,
            )

            const iframeText = document.createTextNode(
                noScriptContent.props.dangerouslySetInnerHTML.__html,
            )

            script.appendChild(scriptText)
            iframe.appendChild(iframeText)
            document.head.appendChild(script)
            noScript.innerHTML =
                noScriptContent.props.dangerouslySetInnerHTML.__html
            document.body.prepend(noScript)
        }
    }

    render() {
        const gtm = gtmParts({
            id: this.props.gtmId,
            additionalEvents: {
                sourcegroup: this.props.gtmGroupname,
                sourceid: this.props.gtmSource,
                age: this.props.age,
                mtongue: this.props.gtmMtongue,
                city: this.props.city,
            },
        })

        return gtm.noScriptAsReact()
    }
}

export default GoogleTagManager
