export default function Whatsapp(props) {
    return (
        <svg
            width="102px"
            height="120px"
            viewBox="0 0 102 120"
            version="1.1"
            {...props}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fillRule="nonzero" fill="currentColor">
                    <path d="M102,0.27 L0,0.27 L0,101.78 L0,101.78 L0,119.78 L20.9,102.27 L102,102.27 L102,0.27 Z M90,90.27 L12,90.27 L12,12.27 L90,12.27 L90,90.27 Z" />
                    <polygon points="67.63 73.94 76.42 65.14 63.56 52.27 53.91 61.92 40.35 48.37 50 38.72 37.14 25.85 28.34 34.65 28.34 49.22 53.06 73.94" />
                </g>
            </g>
        </svg>
    )
}
