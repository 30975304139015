import React, { useState } from "react"
import request from "superagent"
import styles from "./styles.module.scss"

import Button from "../button"
import Loading from "../loading"
import InputContainer from "../input-container"
import ArrowRight from "../../icons/arrow-right"
import Cross from "../../icons/cross"

const RecruiterForm = ({ details, recruiter }) => {
    const [state, setState] = useState({
        loading: false,
        response: null,
        error: null,
        disableSubmit: false,
        values: {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            recruiter: recruiter.author.emailaddress,
        },
    })

    const handleSubmit = (event) => {
        event.preventDefault()

        setState({
            ...state,
            loading: true,
            error: null,
        })

        request
            .post(`${process.env.REACT_APP_API_URL}/recruiters/signup`)
            .send(state.values)
            .then((response) => {
                setState({
                    ...state,
                    response: response.body.data,
                    loading: false,
                })
            })
            .catch((error) => {
                setState({
                    response: null,
                    loading: false,
                    error: error.response
                        ? error.response.body.message
                        : "Er ging iets mis...",
                })
            })
    }

    const handleBlur = (field, value, error, required) => {
        setState({
            ...state,
            values: Object.assign(state.values, {
                [field]: { value: value, error: error, required: required },
            }),
        })
    }

    return (
        <div className={styles.modal}>
            <figure
                className={[styles.image, styles["image--recruiter"]].join(" ")}
            >
                {recruiter.popup && (
                    <img src={recruiter.popup} alt="" loading="lazy" />
                )}
            </figure>

            <form className={styles.form} onSubmit={handleSubmit}>
                {state.response ? (
                    <article className={styles["article--success"]}>
                        <h2
                            className={styles.title}
                            dangerouslySetInnerHTML={{
                                __html: state.response.title,
                            }}
                        />
                        <p
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: state.response.description,
                            }}
                        />
                    </article>
                ) : (
                    <>
                        <article>
                            <h2
                                className={styles.title}
                                dangerouslySetInnerHTML={{
                                    __html: details.title,
                                }}
                            />
                            <p
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: details.description,
                                }}
                            />
                        </article>
                        <div className={styles.fields}>
                            <InputContainer
                                name="first_name"
                                label="Voornaam"
                                variants={["half"]}
                                required={true}
                                type="text"
                                val={
                                    state.values
                                        ? state.values["first_name"]
                                        : ""
                                }
                                save={handleBlur}
                            />
                            <InputContainer
                                name="last_name"
                                label="Achternaam"
                                variants={["half"]}
                                required={true}
                                type="text"
                                val={
                                    state.values
                                        ? state.values["last_name"]
                                        : ""
                                }
                                save={handleBlur}
                            />
                            <InputContainer
                                name="email"
                                label="E-mailadres"
                                variants={["full"]}
                                required={true}
                                type="email"
                                val={state.values ? state.values["email"] : ""}
                                save={handleBlur}
                            />
                            <InputContainer
                                name="phone"
                                label="Telefoonnummer"
                                variants={["full"]}
                                required={true}
                                type="text"
                                val={state.values ? state.values["phone"] : ""}
                                save={handleBlur}
                            />
                            <div className={styles["button-container"]}>
                                <Button
                                    className={[
                                        "blue",
                                        state.loading ? "loading" : "",
                                    ]}
                                    type="submit"
                                >
                                    {state.loading ? (
                                        <Loading color="white" />
                                    ) : (
                                        <>
                                            {details.button}
                                            <ArrowRight />
                                        </>
                                    )}
                                </Button>
                            </div>
                            {state.error && (
                                <div className={styles.error}>
                                    <Cross />
                                    {state.error}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </form>
        </div>
    )
}

export default RecruiterForm
