import React from "react"
import styles from "./styles.module.scss"
import ChevronRight from "../../../icons/chevron-right"

const TagCloud = ({ title, items }) => {
    return (
        <>
            <h2
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className={styles.tags}>
                {items &&
                    items.map((tag, i) => (
                        <div key={i} className={styles.tag}>
                            <ChevronRight />
                            {tag.description}
                        </div>
                    ))}
            </div>
        </>
    )
}

export default TagCloud
