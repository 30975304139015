import styles from "../styles.module.scss"
import { classnames } from "../../../helpers/classnames"

export default function SollicitatieWidget({ vacancyId }) {
    return (
        <section className="section section--yellow-light">
            <div className={classnames("wrapper", styles.wrapper)}>
                <jobboard-application-form
                    form-id="ApplicationForms-1-A"
                    vacancy-id={vacancyId}
                    api-base-url="https://careerrebels.recruitnowcockpit.nl/jobsite"
                    success="window.location.href = '/bedankt/'"
                ></jobboard-application-form>
            </div>
        </section>
    )
}
