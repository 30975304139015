export default function ArrowsRightSmall(props) {
    return (
        <svg width="28" height="14" viewBox="0 0 28 14" {...props}>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M0 8.742h19.513v4.887l7.796-6.68L19.513.325v4.86H0z"
            />
        </svg>
    )
}
