import React from "react"

const Share = (props) => (
    <svg
        {...props}
        width="103px"
        height="103px"
        viewBox="0 0 103 103"
        version="1.1"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero" fill="currentColor">
                <path d="M80.52,29.52 C74.1495661,29.5292067 68.084111,32.249207 63.84,37 L45.54,27.8 C45.8307303,26.3779618 45.9847652,24.9313734 46,23.48 C46.0155854,12.6296621 38.2885925,3.31231177 27.6226712,1.32027829 C16.9567499,-0.671755186 6.38762316,5.22849865 2.48581378,15.3530213 C-1.4159956,25.4775439 2.4600412,36.9447046 11.7043033,42.6256986 C20.9485654,48.3066926 32.9299609,46.5846015 40.2,38.53 L58.5,47.68 C57.9400727,50.5327848 57.9400727,53.4672152 58.5,56.32 L40.2,65.47 C32.9361214,57.4333699 20.9722794,55.7235275 11.7480274,61.403715 C2.52377544,67.0839025 -1.33456554,78.5368621 2.57226165,88.6407255 C6.47908884,98.7445889 17.0387358,104.622593 27.6848288,102.619581 C38.3309218,100.616568 46.0323145,91.3028348 46,80.47 C45.9979383,79.0199084 45.857299,77.5733324 45.58,76.15 L63.88,67 C70.7807636,74.687374 82.0338756,76.6792119 91.1540806,71.8276021 C100.274286,66.9759923 104.911512,56.5310855 102.393291,46.5123621 C99.8750692,36.4936388 90.8502887,29.4826984 80.52,29.52 Z M23.48,34 C17.7025293,33.9779062 13.0327205,29.284226 13.0400851,23.5067177 C13.0474498,17.7292094 17.7292094,13.0474498 23.5067177,13.0400851 C29.284226,13.0327205 33.9779062,17.7025293 34,23.48 C34.0079977,26.2725216 32.9022122,28.9529741 30.9275932,30.9275932 C28.9529741,32.9022122 26.2725216,34.0079977 23.48,34 Z M23.48,91 C17.6973142,90.9779285 13.0253336,86.2761443 13.0400339,80.4934351 C13.0547342,74.7107258 17.7505592,70.0327557 23.5332825,70.0400848 C29.3160058,70.0474138 33.9999579,74.7372721 34,80.52 C33.9973559,83.3055934 32.8868739,85.9757508 30.9134128,87.9417082 C28.9399517,89.9076657 26.2655832,91.0079677 23.48,91 Z M80.48,62.48 C74.6973142,62.4579285 70.0253336,57.7561443 70.0400339,51.9734351 C70.0547342,46.1907258 74.7505592,41.5127557 80.5332825,41.5200848 C86.3160058,41.5274138 90.9999579,46.2172721 91,52 C90.9944872,57.7856592 86.3056592,62.4744872 80.52,62.48 L80.48,62.48 Z" />
            </g>
        </g>
    </svg>
)

export default Share
