import { Link } from "react-router-dom"
import styles from "./styles.module.scss"
import ArrowRight from "../../icons/arrow-right"

export default function HeadingCta({ title, button }) {
    return (
        <header className={styles.header}>
            <h2
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            {button && (
                <Link to={button.link} className={styles.more}>
                    {button.text} <ArrowRight />
                </Link>
            )}
        </header>
    )
}
