import React from "react"
import { isMobile } from "react-device-detect"
import styles from "./styles.module.scss"

import VacanciesSimple from "../vacancies-simple"
import Button from "../button"
import ArrowRight from "../../icons/arrow-right"

const OurVacancies = ({ details, items }) => {
    const { content } = details

    return (
        <section className={styles["our-vacancies"]}>
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                {items && <VacanciesSimple items={items} />}
                <div className={[styles.right].join(" ")}>
                    <div className={styles.content}>
                        <h3
                            className={styles.title}
                            dangerouslySetInnerHTML={{
                                __html: content.title,
                            }}
                        />
                        <p
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: content.description,
                            }}
                        />
                        {content.button && content.button.text && (
                            <Button
                                className={["blue"]}
                                to="#"
                                modal={content.button.link}
                            >
                                {content.button.text}
                                <ArrowRight />
                            </Button>
                        )}
                    </div>
                    {content.image && !isMobile && (
                        <figure>
                            <img
                                src={content.image}
                                alt={content.title}
                                className={styles.image}
                            />
                        </figure>
                    )}
                </div>
            </div>
        </section>
    )
}

export default OurVacancies
