import React, { Component } from "react"
// import SimpleBar from "simplebar-react"
import styles from "./styles.module.scss"

import HeaderMenuItems from "../header-menu-items"
import Facebook from "../../icons/facebook"
import Instagram from "../../icons/instagram"
import LinkedIn from "../../icons/linkedin"
import YouTube from "../../icons/youtube"
import Telephone from "../../icons/telephone"
import Mail from "../../icons/mail"

class HeaderMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: "auto",
            response: null,
        }
    }

    componentDidMount() {
        this.setHeight()
        window.addEventListener("resize", this.setHeight)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setHeight, false)
    }

    setHeight = () => {
        if (window.innerHeight > 894) {
            this.setState({
                height: "auto",
            })
        } else if (
            window.innerHeight <= 894 &&
            window.innerWidth <= 1080 &&
            window.innerWidth > 768
        ) {
            this.setState({
                height: "calc(100vh - 60px)",
            })
        } else if (window.innerHeight <= 894 && window.innerWidth <= 480) {
            this.setState({
                height: "calc(100vh - 55px - 40px)",
            })
        } else if (window.innerHeight <= 894 && window.innerWidth <= 768) {
            this.setState({
                height: "calc(100vh - 55px - 60px)",
            })
        } else {
            this.setState({
                height: "calc(100vh - 80px)",
            })
        }
    }

    render() {
        const close = this.props.close
        const height = this.state.height

        return (
            <div className={styles.container} ref="container">
                <div className={styles.bar} style={{ height: height }}>
                    <HeaderMenuItems close={close} />
                    <div className={styles.contact}>
                        <h4 className={styles["social-title"]}>
                            Let's get social?
                        </h4>
                        <div className={styles.social}>
                            <a
                                href="https://www.facebook.com/careerrebelsNL/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Facebook />
                            </a>
                            <a
                                href="https://www.instagram.com/CareerRebelsNL/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Instagram />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/career-rebels/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LinkedIn />
                            </a>
                            <a
                                href="https://bit.ly/2QUk3lH"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <YouTube />
                            </a>
                        </div>
                        <ul className={styles.bottomMenu}>
                            <li>
                                <a href="tel:010 - 206 20 20">
                                    <Telephone />
                                    010 - 206 20 20
                                </a>
                            </li>
                            <li>
                                <a href="mailto:hello@careerrebels.nl">
                                    <Mail />
                                    hello@careerrebels.nl
                                </a>
                            </li>
                            <li className={styles.address}>
                                Bahialaan 400
                                <br />
                                3065 WC Rotterdam
                            </li>
                            <li className={styles.route}>
                                <a
                                    href="https://www.google.com/maps/place/Career+Rebels/@51.9184495,4.5147421,17z/data=!3m1!4b1!4m5!3m4!1s0x47c432457d39f6f1:0x8d60f464be3b5fca!8m2!3d51.9184495!4d4.5169308"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Routebeschrijving
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderMenu
