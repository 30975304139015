import React, { Component } from "react"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"

import styles from "./styles.module.scss"
import navigationStyles from "../header-menu/styles.module.scss"
import Logo from "../../icons/career-rebels-logo"

import CrossIcon from "../cross-icon"
import HeaderMenu from "../header-menu"
import HeaderMenuHorizontalItems from "../header-menu-horizontal-items"

class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            active: false,
            lastKnownScrollY: 0,
            currentScrollY: 0,
            pin: true,
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.setPadding)
        window.addEventListener("scroll", this.onScroll)

        const snap = navigator.userAgent === "ReactSnap"

        if (snap === false && process.env.NODE_ENV !== "development") {
            this.addPixel()
        }
    }

    onScroll = () => {
        const { lastKnownScrollY, currentScrollY } = this.state

        this.setState({
            currentScrollY: window.pageYOffset,
        })

        if (window.innerWidth <= 768 && window.pageYOffset > 40) {
            if (currentScrollY < lastKnownScrollY) {
                this.setState({
                    pin: true,
                })
            } else if (currentScrollY > lastKnownScrollY) {
                this.setState({
                    pin: false,
                })
            }
        } else if (window.pageYOffset === 0) {
            this.setState({
                pin: true,
            })
        }
        this.setState({
            lastKnownScrollY: currentScrollY,
        })
    }

    addChat = () => {
        if (document.getElementById("jivo-iframe-container")) {
            return
        }

        const s = document.createElement("script")
        s.type = "text/javascript"
        s.async = true
        s.innerHTML =
            "(function(){ const widget_id = 'J06u3hQqhE';const d=document;const w=window;function l(){ const s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = '//code.jivosite.com/script/widget/'+widget_id; let ss = document.getElementsByTagName('script')[0]; ss.parentNode.insertBefore(s, ss);}if(d.readyState==='complete'){l();}else{if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();"
        document.body.appendChild(s)
    }

    addPixel = () => {
        const s = document.createElement("script")
        s.type = "text/javascript"
        s.innerHTML = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '1082603412494107'); fbq('track', 'PageView');`
        document.body.appendChild(s)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setPadding, false)
    }

    setPadding = () => {
        if (window.innerWidth < 768) {
            document.body.style.paddingBottom = `${55}px`
        } else {
            document.body.style.paddingBottom = `0`
        }
    }

    toggleMenu() {
        this.setState({
            active: !this.state.active,
            pin: true,
        })

        if (this.state.active) {
            document.body.classList.remove("disable-scrolling")
        } else {
            document.body.classList.add("disable-scrolling")
        }
    }

    closeMenu() {
        this.setState({
            active: false,
        })
        document.body.classList.remove("disable-scrolling")
    }

    render() {
        const { pin } = this.state

        return (
            <>
                <header
                    className={[
                        styles.header,
                        pin === false ? styles["header--unpinned"] : "",
                    ].join(" ")}
                    ref="header"
                >
                    <Link
                        id="header-logo"
                        to="/"
                        alt="Career Rebels"
                        className={styles.logo}
                        aria-label="Career Rebels"
                    >
                        <Logo />
                    </Link>
                    <div className={styles.headerNav}>
                        <HeaderMenuHorizontalItems
                            close={() => this.closeMenu()}
                        />
                        <div
                            className={styles.item}
                            onClick={() => this.toggleMenu()}
                        >
                            <CrossIcon active={this.state.active.toString()} />
                            <span>Menu</span>
                        </div>
                    </div>
                    <CSSTransition
                        in={this.state.active}
                        timeout={300}
                        classNames={{
                            enterDone:
                                navigationStyles["navigation--enter-done"],
                        }}
                        unmountOnExit
                        onExited={() => {
                            this.setState({
                                active: false,
                            })
                        }}
                    >
                        {() => <HeaderMenu close={() => this.toggleMenu()} />}
                    </CSSTransition>
                </header>
                <CSSTransition
                    in={this.state.active}
                    timeout={300}
                    classNames={{
                        enterDone: styles["overlay--active"],
                    }}
                    unmountOnExit
                    onExited={() => {
                        this.setState({
                            active: false,
                        })
                    }}
                >
                    {() => (
                        <div
                            className={styles.overlay}
                            onClick={() => this.toggleMenu()}
                        />
                    )}
                </CSSTransition>
            </>
        )
    }
}

export default Header
