import React from "react"

const LinkedIn = (props) => (
    <svg
        {...props}
        width="102px"
        height="102px"
        viewBox="0 0 102 102"
        version="1.1"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero" fill="currentColor">
                <path d="M31,46 C35,46 38,43.12 38,38.75 C38,34.38 35,31.5 31,31.5 C27,31.5 24,34.39 24,38.75 C24,43.11 27,46 31,46 Z" />
                <path d="M0,0 L0,102 L102,102 L102,0 L0,0 Z M66.32,90 L56.17,90 L56.17,63.49 L66.32,63.49 L66.32,90 Z M90,90 L78.32,90 L78.32,51.49 L44.17,51.49 L44.17,90 L36.88,90 L36.88,51.38 L24.88,51.38 L24.88,90 L12,90 L12,12 L90,12 L90,90 Z" />
            </g>
        </g>
    </svg>
)

export default LinkedIn
