import React, { Component } from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.scss"
import Modal from "../modal"

class Button extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }
        this.snap = navigator.userAgent === "ReactSnap"
    }

    openModal = (event) => {
        event.preventDefault()

        this.setState({
            open: true,
        })
    }

    closeModal = () => {
        this.setState({
            open: false,
        })
    }

    classNames(classes) {
        if (!this.props.simple) {
            let result = []
            result.push("button")
            result.push(styles["button"])
            if (classes) {
                for (let i = 0; i < classes.length; i++) {
                    result.push(styles[`button--${classes[i]}`])
                }
            }
            return result.join(" ")
        }
    }

    render() {
        const { type, className, children, to, simple, modal } = this.props
        const { recruiter } = this.props || 0

        const { open } = this.state
        let contentClasses = null
        if (!simple) {
            contentClasses = ["content", styles.content].join(" ")
        }

        return type === "dummy" ? (
            <span {...this.props} className={this.classNames(className)}>
                <span className={contentClasses}>{children}</span>
            </span>
        ) : type === "submit" ? (
            <button {...this.props} className={this.classNames(className)}>
                <span className={contentClasses}>{children}</span>
            </button>
        ) : type === "external" ? (
            <a
                {...this.props}
                href={to}
                rel="noopener noreferrer"
                target="_blank"
                className={this.classNames(className)}
            >
                <span className={contentClasses}>{children}</span>
            </a>
        ) : to.includes("modal-") ? (
            <>
                <Link
                    {...this.props}
                    className={this.classNames(className)}
                    onClick={this.openModal}
                >
                    {simple ? (
                        children
                    ) : (
                        <span className={contentClasses}>{children}</span>
                    )}
                </Link>
                {open && (
                    <Modal
                        close={this.closeModal}
                        key="modal"
                        to={modal}
                        slug={to.split("modal-").pop()}
                        recruiter={recruiter}
                    />
                )}
            </>
        ) : to === "#" && modal.includes("modal-zakelijk") ? (
            <></>
        ) : to === "#" && modal.includes("modal-") ? (
            <>
                <Link
                    {...this.props}
                    className={this.classNames(className)}
                    onClick={this.openModal}
                >
                    {simple ? (
                        children
                    ) : (
                        <span className={contentClasses}>{children}</span>
                    )}
                </Link>
                {open && (
                    <Modal
                        close={this.closeModal}
                        key="modal"
                        to={modal}
                        slug={modal.split("modal-").pop()}
                        recruiter={recruiter}
                    />
                )}
            </>
        ) : (
            <Link {...this.props} className={this.classNames(className)}>
                <span className={contentClasses}>{children}</span>
            </Link>
        )
    }
}

export default Button
