import React from "react"
import styles from "./styles.module.scss"

import ArrowsPurple from "../../images/arrows.svg"

const RecruiterHero = ({ title, content }) => {
    return (
        <div
            className={styles.hero}
            style={{
                backgroundImage: `url("${ArrowsPurple}")`,
            }}
        >
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <div className={styles.content}>
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <article
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
            </div>
        </div>
    )
}

export default RecruiterHero
