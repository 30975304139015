import React, { Component } from "react"
import request from "superagent"

import styles from "./styles.module.scss"
import Loading from "../loading"
import { classnames } from "../../helpers/classnames"

class ContentTabs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            response: null,
            loading: true,
            active: 0,
        }
    }

    getContent = (index) => {
        this.setState({
            tempHeight: this.refs.wrapper.clientHeight,
            loading: true,
            active: index,
        })

        if (this.props.content) {
            this.setState({
                response: this.props.content[index],
                loading: false,
                tempHeight: null,
            })
        } else {
            request
                .get(`${process.env.REACT_APP_API_URL}/pages/employers/content`)
                .query({ index: index })
                .then((response) => {
                    this.setState({
                        response: response.body,
                        loading: false,
                        tempHeight: null,
                    })
                })
                .catch(() => {
                    this.setState({
                        response: null,
                        loading: false,
                    })
                })
        }
    }

    componentDidMount() {
        const tab = window.location.hash.slice(1)
        let id = 0

        if (tab === "flex") {
            id = 0
        }
        if (tab === "recruitment") {
            id = 1
        }
        if (tab === "hr") {
            id = 2
        }
        if (tab === "education") {
            id = 3
        }

        this.getContent(id)
    }

    render() {
        const { response, loading, tempHeight, active } = this.state
        const { title, categories, background } = this.props

        return (
            <section
                className={classnames(
                    styles.tabs,
                    styles[`tabs--${background}`],
                )}
            >
                <div
                    className={[styles.wrapper, "wrapper"].join(" ")}
                    ref="wrapper"
                    style={{ height: tempHeight }}
                >
                    <figure
                        className={[
                            styles.figure,
                            loading ? styles["figure--loading"] : "",
                        ].join(" ")}
                    >
                        {loading ? (
                            <Loading />
                        ) : (
                            <img src={response.image} alt="" />
                        )}
                    </figure>
                    <div className={styles.content}>
                        {title && (
                            <h2
                                className={styles.title}
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                        <ul className={styles.list}>
                            {categories.map((category, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={() => this.getContent(i)}
                                        className={
                                            active === i ? "is-active" : null
                                        }
                                    >
                                        {category}
                                    </li>
                                )
                            })}
                        </ul>
                        <article className={styles.description}>
                            {loading ? (
                                <Loading />
                            ) : (
                                <>
                                    <h2
                                        dangerouslySetInnerHTML={{
                                            __html: response.title,
                                        }}
                                    />
                                    <div
                                        className={styles.article}
                                        dangerouslySetInnerHTML={{
                                            __html: response.description,
                                        }}
                                    />
                                </>
                            )}
                        </article>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContentTabs
