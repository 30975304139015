import React from "react"
import { Helmet } from "react-helmet"

const SEO = (props) => {
    const title = props.title || "Career Rebels"
    const description = props.description || "Het eigenwijze uitzendbureau"
    const image =
        props.image ||
        "https://topsecret.careerrebels.nl/resources/uploads/2019/09/CR_logo_RGB_klein_01.jpg"
    const url =
        props.url || `${process.env.REACT_APP_URL}${window.location.pathname}`

    // let indexable = ""
    //
    // if (process.env.REACT_APP_ENV === "development") {
    //     indexable = "noindex"
    // } else {
    //     indexable = window.location.pathname.startsWith("/bedankt")
    //         ? "noindex"
    //         : "index"
    // }

    return (
        <Helmet
            link={[
                {
                    rel: "canonical",
                    href: url,
                },
            ]}
            title={`${title} - Career Rebels`}
            defer={false}
            meta={[
                {
                    name: "description",
                    content: description,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:image",
                    content: image,
                },
                {
                    property: "og:image:alt",
                    content:
                        "https://topsecret.careerrebels.nl/resources/uploads/2019/09/CR_logo_RGB_klein_01.jpg",
                },
                {
                    property: "og:url",
                    content: `${process.env.REACT_APP_URL}${window.location.pathname}`,
                },
                {
                    property: "og:description",
                    content: description,
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "twitter:image",
                    content: image,
                },
                // {
                //     name: "robots",
                //     content: indexable,
                // },
            ]}
        />
    )
}

export default SEO
