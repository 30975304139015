import React from "react"
import { slugify } from "../../../helpers/slugify"
import styles from "./styles.module.scss"

const HoverTabs = ({ items }) => {
    const [state, setState] = React.useState()

    const handleClick = (event) => {
        const slug = event.currentTarget.getAttribute("data-tab")
        setState(slug)
    }

    React.useEffect(() => {
        const slug = slugify(items[0].title)
        setState(slug)
    }, [items])

    return (
        <div className={styles.tabs}>
            <div className={styles.navigation}>
                {items &&
                    items.map((item) => {
                        const slug = slugify(item.title)

                        return (
                            <div
                                key={slug}
                                data-tab={slug}
                                className={[
                                    styles.tab,
                                    state === slug && styles.active,
                                ].join(" ")}
                                onMouseEnter={handleClick}
                            >
                                <h3 className={styles.title}>{item.title}</h3>
                                <span className={styles.subtitle}>
                                    {item.subtitle}
                                </span>
                            </div>
                        )
                    })}
            </div>
            <div className={styles.content}>
                {items &&
                    items.map((item) => {
                        const slug = slugify(item.title)

                        return (
                            <div
                                key={slug}
                                id={`${slug}`}
                                className={[
                                    styles.block,
                                    state === slug && styles.active,
                                ].join(" ")}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.content,
                                    }}
                                />
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default HoverTabs
