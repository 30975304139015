import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import NotFoundContainer from "../../components/not-found"

export default function NotFoundPage() {
    return (
        <Layout seo={{ title: "Pagina niet gevonden" }} yoast={false}>
            <Helmet robots="noindex" />
            <NotFoundContainer />
        </Layout>
    )
}
