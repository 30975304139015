import styles from "../detail.module.scss"

export default function VacatureDetails({ details }) {
    return (
        <div className={styles.details}>
            <div>
                Salaris:{` `}
                <span>{details.salaris}</span>
            </div>
            <div>
                Dienstverband:{` `}
                <span>{details.dienstverband}</span>
            </div>
            <div>
                Werkuren:{` `}
                <span>{details.werkuren}</span>
            </div>
            <div>
                Locatie:{` `}
                <span>{details.locatie}</span>
            </div>
            <div>
                Branche: {` `}
                <span>{details.branche}</span>
            </div>
            <div>
                Datum geplaatst: {` `}
                <span>{details.datum_geplaatst}</span>
            </div>
            <div>
                Geldig tot: {` `}
                <span>{details.geldig_tot}</span>
            </div>
        </div>
    )
}
