import React from "react"
import styles from "./styles.module.scss"

import Item from "../vacancies-simple-item"

const VacanciesSimple = ({ items }) => {
    return (
        <div className={styles.vacancies}>
            {items &&
                items.map((vacancy, i) => <Item key={i} item={vacancy} />)}
        </div>
    )
}

export default VacanciesSimple
