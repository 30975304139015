import React from "react"
import styles from "../vacancy/styles.module.scss"

import Button from "../button"
import ArrowRight from "../../icons/arrow-right"

const FloatingButton = ({ recruiter }) => {
    return (
        <div className={styles.floating}>
            <Button
                to="#"
                modal="/modal-recruiter-aanmelden"
                recruiter={recruiter}
                className={[
                    "white",
                    "space-between",
                    styles["floating-button"],
                ].join(" ")}
            >
                Ik kies voor {recruiter.name} <ArrowRight />
            </Button>
        </div>
    )
}

export default FloatingButton
