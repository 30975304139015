import React from "react"
import styles from "./styles.module.scss"

import Button from "../button"
import CheckCircle from "../../icons/check-circle"
import ArrowRight from "../../icons/arrow-right"

const Skills = ({ recruiter }) => {
    const data = recruiter.introductie

    return (
        <div className="wrapper has-content-centered">
            <div className={styles.skills}>
                <div className={styles.skillList}>
                    <div className={styles.skillColumn}>
                        <h2 className={styles["section--title"]}>
                            Soft Skills
                        </h2>
                        <div className={styles.list}>
                            {data.soft_skills.map((skill) => (
                                <div
                                    key={skill.skill}
                                    className={styles["list--item"]}
                                >
                                    <CheckCircle check="#fff" />
                                    <span>{skill.skill}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.skillColumn}>
                        <h2 className={styles["section--title"]}>
                            Hard Skills
                        </h2>
                        <div className={styles.list}>
                            {data.hard_skills.map((skill) => (
                                <div
                                    key={skill.skill}
                                    className={styles["list--item"]}
                                >
                                    <CheckCircle check="#fff" />
                                    <span>{skill.skill}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={[styles.buttons, styles["buttons--centered"]].join(
                    " ",
                )}
            >
                <h3 className={styles["button--bar-title"]}>
                    Ja, dit past bij mij!
                </h3>
                <Button
                    to="#"
                    modal="/modal-recruiter-aanmelden"
                    className={["blue", "space-between"]}
                    recruiter={recruiter}
                >
                    Ik kies voor {recruiter.name} <ArrowRight />
                </Button>
            </div>
        </div>
    )
}

export default Skills
