import styles from "../styles.module.scss"
import VacancyGrid from "../grid"
import { classnames } from "../../../helpers/classnames"

export default function VacatureRelated({ related }) {
    return (
        <section className="section section--light">
            <div className={classnames("wrapper px-8", styles.wrapper)}>
                <h2 className={styles.title}>
                    Deze banen vind je misschien ook leuk
                </h2>
                <VacancyGrid items={related} />
            </div>
        </section>
    )
}
