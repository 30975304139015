import React from "react"
import styles from "./styles.module.scss"

const ContentSimple = ({ title, content, color }) => {
    return (
        <article className={styles.content}>
            <h1
                className={styles.title}
                style={{ color: color }}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </article>
    )
}

export default ContentSimple
