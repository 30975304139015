import React from "react"

const ArrowDown = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="32"
        viewBox="0 0 19 32"
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6.98 0v21.116H0L9.539 32 19 21.116h-6.941V0z"
        />
    </svg>
)

export default ArrowDown
