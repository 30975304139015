import React from "react"
import ReactDOM from "react-dom"
import { useFetch } from "use-http"

import styles from "./styles.module.scss"
import Cross from "../../icons/cross"
import Loading from "../loading"
import RecruiterForm from "../modal-form/recruiter"
import ModalForm from "../modal-form"

const Modal = ({ slug, close, recruiter = 0, uurtarief = false }) => {
    const modalRef = React.createRef()

    const request = useFetch(
        `${process.env.REACT_APP_API_URL}/popups/detail/${slug}`,
        {},
        [],
    )

    const handleClickOutside = React.useCallback(
        (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                close()
            }
        },
        [close, modalRef],
    )

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [handleClickOutside])

    return ReactDOM.createPortal(
        <div className={styles.modal}>
            {request.loading ? (
                <Loading color="#fff" />
            ) : request.error ? (
                <p>Er ging iets fout...</p>
            ) : (
                <div className={styles.content} ref={modalRef}>
                    <span className={styles.close} onClick={close}>
                        <Cross />
                    </span>
                    {request.data &&
                    request.data.form &&
                    slug === "recruiter-aanmelden" ? (
                        <RecruiterForm />
                    ) : request.data &&
                      request.data.form &&
                      slug === "bereken-uitzendtarief" ? (
                        <ModalForm
                            details={request.data}
                            slug={slug}
                            calculator={{
                                functie: {
                                    value: uurtarief.function,
                                    error: false,
                                    required: true,
                                },
                                uurtarief: {
                                    value: uurtarief.wage,
                                    error: false,
                                    required: true,
                                },
                                uren: {
                                    value: uurtarief.hours,
                                    error: false,
                                    required: true,
                                },
                            }}
                        />
                    ) : request.data && request.data.form ? (
                        <ModalForm details={request.data} slug={slug} />
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </div>,
        document.querySelector("#careerrebels"),
    )
}

export default Modal
