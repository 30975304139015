import "./style.css"

export default function ApplyNowForm() {
    return (
        <section className="section section--yellow stick-bottom">
            <div className="wrapper">
                <div className="flex justify-center">
                    <recruitnow-open-enrollment-widget
                        api-base-url="https://careerrebels.recruitnowcockpit.nl"
                        open-enrollment-form-id="CandidateEnrollmentForms-1-A"
                        submit-success="location.href = '/bedankt-voor-je-inschrijving';"
                        submit-fail="location.href = '/inschrijven';"
                    ></recruitnow-open-enrollment-widget>
                </div>
            </div>
        </section>
    )
}
