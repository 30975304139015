import React from "react"
import styles from "./styles.module.scss"

import Logo from "../../icons/logo"
import ArrowsDown from "../../icons/arrows-down"

const ContentImage = ({ content, image, title, logo, type }) => {
    return (
        <section className={[styles.text, styles[`text--${type}`]].join(" ")}>
            <ArrowsDown className={styles.arrows} />
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <figure className={styles.figure}>
                    <img src={image} alt={title} />
                </figure>
                <div className={styles.content}>
                    {logo && (
                        <div className={styles.logo}>
                            <Logo />
                        </div>
                    )}
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <article
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </div>
            </div>
        </section>
    )
}

export default ContentImage
