import React from "react"
import styles from "./styles.module.scss"

const Introduction = ({ recruiter }) => {
    return (
        <div className={[styles.introduction, "wrapper"].join(" ")}>
            <div className={styles.hero}>
                <figure className={styles.image}>
                    <div
                        className="video"
                        dangerouslySetInnerHTML={{
                            __html: recruiter.video.video,
                        }}
                    />
                </figure>
                <header
                    className={[
                        styles.heading,
                        recruiter.afwezig ? styles["item--missed"] : "",
                    ].join(" ")}
                >
                    <span>{recruiter.fullname}</span>
                    <i>"{recruiter.introductie.citaat}"</i>
                    {recruiter.afwezig && (
                        <span className={styles.afwezig}>Op vakantie!</span>
                    )}
                </header>
            </div>
        </div>
    )
}

export default Introduction
