import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import { useState } from "react"

export default function SliderRecruiters({ recruiters }) {
    const [isOpen, setIsOpen] = useState(false)
    const [youtubeId, setYoutubeId] = useState("L61p2uyiMSo")

    const handleClick = async (video) => {
        await setYoutubeId(video)
        setIsOpen(true)
    }

    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId={youtubeId}
                onClose={() => setIsOpen(false)}
            />
            <Swiper
                modules={[Autoplay, Navigation]}
                speed={2000}
                direction="horizontal"
                cssMode={true}
                centeredSlides={true}
                freeMode={true}
                spaceBetween={24}
                slidesPerView={1}
                slidesPerGroup={1}
                autoplay={{
                    delay: 5000,
                    stopOnLastSlide: false,
                }}
                grabCursor={true}
                scrollbar={{
                    el: ".swiper-scrollbar",
                    hide: true,
                    draggable: true,
                }}
                loop={true}
                breakpoints={{
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                        sliderPerGroup: 3,
                    },
                }}
            >
                {recruiters.map((item) => (
                    <SwiperSlide key={item.id} className="recruiter-slide">
                        <img
                            src={item.image}
                            alt=""
                            onClick={() => handleClick(item.video)}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}
