import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"

export default function HoursPage() {
    return (
        <Layout seo={{ title: "Mijn uren" }} yoast={false}>
            <Helmet robots="noindex,nofollow" />
            <iframe
                title="Mijn uren"
                src="https://careerrebels-kk.kentro.nl/KentroKonnekt/Login.aspx"
                width="100%"
                height="100vh"
                style={{ height: "100vh", border: "0" }}
            />
        </Layout>
    )
}
