export default function ArrowRight(props) {
    return (
        <svg
            width="38px"
            height="12px"
            viewBox="0 0 38 12"
            version="1.1"
            {...props}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="currentColor">
                    <polygon points="0.287 7.672 30.98 7.672 30.98 11.713 37.426 6.191 30.98 0.713 30.98 4.732 0.287 4.732" />
                </g>
            </g>
        </svg>
    )
}
