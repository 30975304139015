import React from "react"

const Organization = () => {
    return (
        <div className="video" style={{ marginTop: "24px" }}>
            <iframe
                width="1200"
                height="675"
                src="https://www.youtube-nocookie.com/embed/pmARs-9PL-M"
                frameBorder="0"
                title="Organisatie video"
                loading="lazy"
                allow="encrypted-media; picture-in-picture"
                allowFullScreen
            />
        </div>
    )
}

export default Organization
