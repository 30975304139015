import React from "react"
import useFetch from "use-http"

import styles from "./styles.module.scss"

import Location from "../location"
import Loading from "../loading"

const Sidebar = () => {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/locations`,
        {},
        [],
    )

    return loading ? (
        <Loading />
    ) : (
        <aside className={styles.sidebar}>
            {response &&
                response.map((location, i) => (
                    <Location key={i} location={location} type="large" />
                ))}
        </aside>
    )
}

export default Sidebar
