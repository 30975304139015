import { useState } from "react"
import { useHistory } from "react-router"
import Select from "react-select"
import styles from "./styles.module.scss"
import ArrowsRight from "../../icons/arrows-right"

const customStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (provided) => ({
        ...provided,
        background: "#fff",
        color: "#000",
        borderRadius: "0",
        minHeight: "78px",
        height: "78px",
    }),
    valueContainer: (provided) => ({
        ...provided,
        // height: "78px",
        padding: "0 12px",
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "#000",
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    input: (provided) => ({
        ...provided,
        margin: "0px",
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#4cbcc9",
        opacity: 1,
        fontSize: "1.5rem",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: "78px",
    }),
}

export default function VacaturesFilter({ facets, homepage = false }) {
    const [values, setValues] = useState({
        branche: false,
        dienstverband: false,
        region: false,
    })
    const history = useHistory()

    const handleChange = (event) => {
        event.preventDefault()

        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }

    const handleSelect = (data, event) => {
        if (data.length) {
            if (data.length) {
                setValues({
                    ...values,
                    [event.name]: data,
                })
            } else {
                delete values[event.name]
            }
        } else {
            setValues({
                ...values,
                [event.name]: Array.of(data),
            })
        }
    }

    const handleReset = async () => {
        setValues({})
        window.location = `${window.location.origin}${window.location.pathname}`
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        let query = ""

        const filtered = Object.entries(values).filter(([key, value]) => {
            return value
        })

        filtered.map(([key, value]) => {
            if (Array.isArray(value)) {
                const selected = value.map((v) => v.value)
                query += `&${key}=${selected.join("|")}`
            } else {
                query += `&${key}=${value}`
            }
            return query
        })

        query = "?" + query.substring(1)
        history.push("/vacatures" + query)
    }

    return (
        <section className="section section--black relative">
            {!homepage && <ArrowsRight className={styles.arrows} />}
            <div className="wrapper">
                {homepage ? (
                    <h2 className={styles.filterTitle}>
                        Hé halloo, welkom bij{" "}
                        <span className="text-yellow">Career Rebels</span>.
                        Laten we gelijk beginnen!
                    </h2>
                ) : (
                    <h2 className={styles.filterTitle}>
                        Kom maar door met die{" "}
                        <span className="text-red">toekomst!</span>
                    </h2>
                )}
                <form
                    className={styles.filter}
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                >
                    <input
                        type="text"
                        id="term"
                        name="term"
                        placeholder="Ik zoek..."
                        className={styles.input}
                        defaultValue={facets.term}
                        onChange={handleChange}
                    />
                    <Select
                        id="branche"
                        name="branche"
                        options={facets.branches}
                        defaultValue={facets.branches.filter(
                            (option) => option.selected,
                        )}
                        isMulti={true}
                        classNamePrefix="select"
                        onChange={handleSelect}
                        styles={customStyles}
                        placeholder="Kies je vakgebied..."
                    />
                    {!homepage && (
                        <>
                            <Select
                                id="dienstverband"
                                name="dienstverband"
                                options={facets.dienstverband}
                                defaultValue={facets.dienstverband.filter(
                                    (option) => option.selected,
                                )}
                                isMulti={true}
                                classNamePrefix="select"
                                onChange={handleSelect}
                                styles={customStyles}
                                placeholder="Kies je dienstverband..."
                            />
                            <Select
                                id="region"
                                name="region"
                                options={facets.region}
                                defaultValue={facets.region.filter(
                                    (option) => option.selected,
                                )}
                                isMulti={true}
                                classNamePrefix="select"
                                onChange={handleSelect}
                                styles={customStyles}
                                placeholder="Kies je regio..."
                            />
                        </>
                    )}
                    <input
                        className={styles.submit}
                        type="submit"
                        value="Zoeken"
                    />
                    <input
                        className={styles.reset}
                        type="reset"
                        value="Reset filters"
                    />
                </form>
            </div>
        </section>
    )
}
