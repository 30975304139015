import React from "react"
import { Redirect, useParams } from "react-router-dom"
import { useFetch } from "use-http"
import { InlineWidget } from "react-calendly"

import PageLoading from "../../components/page-loading"
import Layout from "../../components/layout"
import ImageCta from "../../components/image-cta"
import Stories from "../../components/stories"
import ContentImage from "../../components/content-image"
import ContentVideo from "../../components/content/vieo"
import ContentTabs from "../../components/content-tabs"
import CaseHero from "../../components/case-hero"
import ContentPage from "../../components/content-page"
import Vacancies from "../../components/our-vacancies"
import Calculator from "../../components/blocks/calculator"
import HoverTabs from "../../components/blocks/hover-tabs"
import SalesCases from "../../components/blocks/sales-cases"
import Hero from "../../components/blocks/hero"
import ImageSlider from "../../components/blocks/image-slider"
import Video from "../../components/blocks/video"
import Testimonial from "../../components/blocks/testimonial"
import ContactForm from "../../components/blocks/contact-form"
import VacancySlider from "../../components/blocks/vacancy-slider"
import HeroImage from "../../components/blocks/hero-image"
import Timeline from "../../components/blocks/timeline"
import TagCloud from "../../components/blocks/tag-cloud"
import ContentVideoBlock from "../../components/blocks/content-video"
import SliderRecruiters from "../../components/blocks/slider-recruiters"
import RecruiterBlock from "../../components/blocks/recruiter"
import InternalVacancies from "../../components/blocks/internal-vacancies"
import TitleBlocked from "../../components/blocks/title-block"
import VacanciesBlock from "../../components/blocks/vacancies"
import CardsBlock from "../../components/blocks/cards"

export default function DefaultPage() {
    const { page } = useParams()

    const { loading, error, data } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/${page}`,
        {},
        [page],
    )

    return loading ? (
        <PageLoading />
    ) : error ? (
        <Redirect to={`/niet-gevonden/`} />
    ) : (
        <Layout seo={data.yoast} yoast={true}>
            <>
                {data.result.map((item) => {
                    switch (item.type) {
                        case "image-cta":
                            return <Hero key={item.id} detail={item.block} />
                        case "stories":
                            return (
                                <Stories
                                    key={item.id}
                                    title={item.block.title}
                                    button={item.block.button}
                                    items={item.block.items}
                                    multiple={true}
                                    background={item.block.background}
                                />
                            )
                        case "video":
                            return (
                                <ContentVideo
                                    key={item.id}
                                    title={item.block.title}
                                    video={item.block.video}
                                    type={item.block.background}
                                />
                            )
                        case "tabs":
                            return (
                                <ContentTabs
                                    key={item.id}
                                    title={item.block.title || null}
                                    categories={item.block.categories}
                                    content={item.block.content}
                                    background={item.block.background}
                                />
                            )
                        case "hero":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <CaseHero
                                        variants={[
                                            "no-margin",
                                            item.block.background,
                                        ]}
                                        title={item.block.title}
                                        content={item.block.content}
                                        button={item.block.button}
                                        image={item.block.image}
                                    />
                                </section>
                            )
                        case "vacancies-featured":
                            return (
                                <Vacancies key={item.id} details={item.block} />
                            )
                        case "text":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <ContentPage
                                            title={item.block.title}
                                            content={item.block.content}
                                            title_color={item.block.title_color}
                                        />
                                    </div>
                                </section>
                            )
                        case "calendly":
                            return (
                                <div
                                    key={item.id}
                                    className="wrapper wrapper--content"
                                >
                                    <InlineWidget
                                        url={item.block.url}
                                        styles={{ height: "920px" }}
                                    />
                                </div>
                            )
                        case "text-calculator":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <Calculator block={item.block} />
                                    </div>
                                </section>
                            )
                        case "hover-tabs":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <HoverTabs
                                            items={item.block.items}
                                            submissionText={
                                                item.block.submissionText
                                            }
                                        />
                                    </div>
                                </section>
                            )
                        case "sales-cases":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <SalesCases
                                            title={item.block.title}
                                            description={item.block.description}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "image-slider":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <ImageSlider
                                            title={item.block.title}
                                            description={item.block.description}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "vacancy-slider":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <VacancySlider
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "video-2":
                            return (
                                <section
                                    key={item.id}
                                    className="section section--yellow-light"
                                >
                                    <div className="wrapper">
                                        <Video
                                            title={item.block.title}
                                            video={item.block.video}
                                        />
                                    </div>
                                </section>
                            )
                        case "testimonial":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <Testimonial
                                            title={item.block.title}
                                            description={item.block.description}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "contact-form":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <ContactForm
                                            id={item.id}
                                            block={item.block.id}
                                            title={item.block.title}
                                            description={item.block.description}
                                            description2={
                                                item.block.description_2
                                            }
                                            fields={item.block.fields}
                                            button={item.block.button}
                                        />
                                    </div>
                                </section>
                            )
                        case "image-text":
                            return (
                                <ContentImage
                                    key={item.id}
                                    title={item.block.title}
                                    image={item.block.image}
                                    content={item.block.description}
                                    type={item.block.background}
                                />
                            )
                        case "hero-image":
                            return (
                                <HeroImage
                                    key={item.id}
                                    image={item.block.image}
                                    height={item.block.height}
                                />
                            )
                        case "custom-html":
                            return (
                                <section key={item.id} className="section">
                                    <div className="wrapper">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.block.html,
                                            }}
                                        />
                                    </div>
                                </section>
                            )
                        case "timeline":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <Timeline
                                            title={item.block.title}
                                            description={item.block.description}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "tags":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <TagCloud
                                            title={item.block.title}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "content-video":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <ContentVideoBlock
                                            content={item.block.content}
                                            video={item.block.video}
                                        />
                                    </div>
                                </section>
                            )
                        case "slider-recruiters":
                            return (
                                <section
                                    key={item.id}
                                    id={item.block.anchor}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <SliderRecruiters
                                            recruiters={item.block.recruiters}
                                        />
                                    </div>
                                </section>
                            )
                        case "recruiter":
                            return (
                                <section
                                    key={item.id}
                                    id={item.block.anchor}
                                    className={[
                                        "section",
                                        "stick-bottom",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <RecruiterBlock
                                            title={item.block.title}
                                            content={item.block.content}
                                            recruiter={item.block.recruiter}
                                            button={item.block.button}
                                        />
                                    </div>
                                </section>
                            )
                        case "internal-vacancies":
                            return (
                                <section
                                    key={item.id}
                                    id={item.block.anchor}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <InternalVacancies
                                            title={item.block.title}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "title-block":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <TitleBlocked
                                            content={item.block.content}
                                        />
                                    </div>
                                </section>
                            )
                        case "vacatures":
                            return (
                                <section
                                    key={item.id}
                                    id={item.block.anchor}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <VacanciesBlock
                                            title={item.block.title}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        case "cards":
                            return (
                                <section
                                    key={item.id}
                                    className={[
                                        "section",
                                        `section--${item.block.background}`,
                                    ].join(" ")}
                                >
                                    <div className="wrapper">
                                        <CardsBlock
                                            title={item.block.title}
                                            items={item.block.items}
                                        />
                                    </div>
                                </section>
                            )
                        default:
                            return null
                    }
                })}
                {data.cta && (
                    <ImageCta
                        variants={["purple", "large-image"]}
                        detail={data.cta}
                    />
                )}
            </>
        </Layout>
    )
}
