import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"

import styles from "./styles.module.scss"
import Button from "../button"
import ArrowRight from "../../icons/arrow-right"
import { history } from "../../helpers/history"
import { classnames } from "../../helpers/classnames"

class ContentPage extends Component {
    appendButton = () => {
        let divs = this.refs.article.getElementsByClassName("btn")
        if (divs.length > 0) {
            for (let i = 0; i < divs.length; i++) {
                let link = divs[i].getAttribute("data-link")
                let text = divs[i].getAttribute("data-text")
                let type = ""
                if (link.includes("http")) {
                    type = "external"
                }

                ReactDOM.render(
                    <Router history={history}>
                        <Button className={["blue"]} to={link} type={type}>
                            {text}
                            <ArrowRight />
                        </Button>
                    </Router>,
                    divs[i],
                )
            }
        }
    }

    componentDidMount() {
        this.appendButton()
    }

    render() {
        const { content, title, title_color } = this.props

        return (
            <article className={styles.content}>
                <h1
                    className={classnames(styles.title, `text-${title_color}`)}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div
                    ref="article"
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </article>
        )
    }
}

export default ContentPage
