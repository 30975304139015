export default function Telephone(props) {
    return (
        <svg
            width="90px"
            height="90px"
            viewBox="0 0 90 90"
            version="1.1"
            {...props}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fillRule="nonzero" fill="currentColor">
                    <polygon points="47.86 67.51 65.92 49.45 90 73.53 73.53 90 46.26 90 0 43.74 0 16.47 16.47 0 40.55 24.08 22.49 42.14" />
                </g>
            </g>
        </svg>
    )
}
