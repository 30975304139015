import React from "react"
import { Redirect, useParams } from "react-router-dom"
import useFetch from "use-http"

export default function IndeedPage() {
    const { slug } = useParams() // vacancies-55775-A

    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/cr/v1/vacatures/indeed/${slug}`,
        {},
        [slug],
    )

    if (loading) {
        return <div>Je wordt binnen enkele seconden doorgestuurd nar de vacature...</div>
    }

    if (response.error) {
        return <Redirect to="/404" />
    }

    window.location.href = response?.url
}
