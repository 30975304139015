import React from "react"
import styles from "./styles.module.scss"

import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const ImageSlider = ({ title, description, items }) => {
    return (
        <div className={styles.slider}>
            <div className={styles.row}>
                <h2
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
            <div className={styles.row}>
                <Swiper
                    modules={[Autoplay, Navigation]}
                    speed={2000}
                    direction="horizontal"
                    cssMode={true}
                    spaceBetween={24}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    autoplay={{
                        delay: 5000,
                        stopOnLastSlide: false,
                    }}
                    loop={true}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                            sliderPerGroup: 3,
                        },
                    }}
                >
                    {items &&
                        items.map((item, i) => (
                            <SwiperSlide key={i} className={styles.item}>
                                <div>
                                    <figure className={styles.figure}>
                                        <img
                                            className={styles.image}
                                            src={item.image}
                                            alt=""
                                        />
                                        <figcaption className={styles.caption}>
                                            <span>{item.description}</span>
                                        </figcaption>
                                    </figure>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </div>
    )
}

export default ImageSlider
