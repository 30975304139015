import React from "react"

const Cross = (props) => (
    <svg {...props} width="14" height="14" viewBox="0 0 14 14">
        <g fill="none" fillRule="nonzero" stroke="currentColor" strokeWidth="3">
            <path d="M1.417 1.418l11.165 11.164M1.417 12.582L12.582 1.418" />
        </g>
    </svg>
)

export default Cross
