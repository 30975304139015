import styles from "../../vacancy/styles.module.scss"
import { Link } from "react-router-dom"
import ArrowRight from "../../../icons/arrow-right"

export default function CardsItem({ item }) {
    return (
        <Link to={`${item.link}`} className={styles.item}>
            <figure className={styles.image}>
                <div
                    className={styles.background}
                    style={{ backgroundImage: `url(${item.image})` }}
                />
            </figure>
            <header className={styles.heading}>
                <span dangerouslySetInnerHTML={{ __html: item.title }} />
            </header>
            <div className={styles.content}>
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                />

                <span className={styles.more}>
                    Lees meer
                    <ArrowRight />
                </span>
            </div>
        </Link>
    )
}
