import React from "react"
import styles from "./styles.module.scss"

const Loading = ({ color = "#000" }) => {
    const isIE = !!document.documentMode

    return (
        <span className={["loading", styles.loading].join(" ")}>
            {isIE ? (
                <div
                    className={styles["spinner-ie"]}
                    style={{ color: color }}
                />
            ) : (
                <svg>
                    <circle
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                        style={{ stroke: color }}
                    />
                </svg>
            )}
        </span>
    )
}

export default Loading
