import styles from "../styles.module.scss"

export default function FunctionSection({ description }) {
    return (
        <div className="p-8">
            <h2 className={styles.title}>Hoe ziet jouw dag eruit?</h2>
            <div
                className={styles.article}
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    )
}
