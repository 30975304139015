import styles from "./styles.module.scss"

export default function HeroImage({ image, height = 500 }) {
    return (
        <section className={styles.hero}>
            <figure
                className={styles.figure}
                style={{
                    maxHeight: `${height}px`,
                }}
            >
                <img src={image} alt="" />
            </figure>
        </section>
    )
}
