import React, { Component } from "react"
import styles from "./styles.module.scss"

class CrossIcon extends Component {
    render() {
        return (
            <div
                {...this.props}
                className={[
                    styles.cross,
                    this.props.active === "true"
                        ? styles["cross--active"]
                        : null,
                ].join(" ")}
            >
                <span />
                <span />
            </div>
        )
    }
}

export default CrossIcon
