import React from "react"

const ArrowUp = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="32"
        viewBox="0 0 19 32"
    >
        <g
            transform="translate(9.500000, 16.000000) rotate(-180.000000) translate(-9.500000, -16.000000)"
            fill="currentColor"
        >
            <polygon points="6.98 0 6.98 21.116 0 21.116 9.539 32 19 21.116 12.059 21.116 12.059 0" />
        </g>
    </svg>
)

export default ArrowUp

