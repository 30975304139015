import styles from "./styles.module.scss"
import HeadingCta from "../../heading-cta"
import CardsItem from "./item"

export default function CardsBlock({ title, items }) {
    return (
        <div className={styles.block}>
            {title && <HeadingCta title={title} />}
            <div className={styles.grid}>
                {items.map((item) => (
                    <CardsItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    )
}
