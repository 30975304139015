import React from "react"
import useFetch from "use-http"

import Layout from "../../components/layout"
import ContentImage from "../../components/content-image-employers"
import ContentTabs from "../../components/content-tabs"
import Stories from "../../components/stories"
import ImageCta from "../../components/image-cta"
import PageLoader from "../../components/page-loading"

export default function EmployersPage() {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/onze-diensten`,
        {},
        [],
    )

    return loading ? (
        <PageLoader />
    ) : (
        <Layout seo={response.yoast}>
            <ContentImage
                title={response.pageHero.title}
                content={response.pageHero.content}
                button={response.pageHero.button}
            />
            <ContentTabs categories={response.categories} />
            {response.stories && (
                <Stories
                    title={response.stories.title}
                    button={response.stories.button}
                    items={response.stories.items}
                    background={`light`}
                />
            )}
            <ImageCta variants={["blue"]} detail={response.cta} />
        </Layout>
    )
}
