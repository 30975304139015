import React, { useEffect } from "react"
import styles from "./styles.module.scss"

import ScrollToTop from "../scroll-to-top"
import ScrollToTopOnMount from "../scroll-to-top/onMount"
import ToTop from "../scroll-to-top/button"
import Yoast from "../seo/yoast"

export default function Layout({ children, toTop = true, seo }) {
    const isMobile = window.innerWidth < 768

    useEffect(() => {
        const el = document.getElementById(window.location.hash.slice(1))

        if (el) {
            window.scrollTo({
                left: 0,
                top: el.offsetTop - 50,
                behavior: "smooth",
            })
        }
        // eslint-disable-next-line
    }, [window.location.hash])

    return (
        <>
            <Yoast yoast={seo} />
            <div className={styles.layout}>
                {toTop === false ? (
                    <ScrollToTop>{children}</ScrollToTop>
                ) : (
                    children
                )}
                {toTop && <ScrollToTopOnMount />}
                {!isMobile && <ToTop />}
            </div>
        </>
    )
}
