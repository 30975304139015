import useFetch from "use-http"
import styles from "./styles.module.scss"

import VacancyGrid from "../../components/vacancy/grid"
import Button from "../button"
import ArrowRight from "../../icons/arrow-right"
import PageLoading from "../page-loading"

export default function NotFound() {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/home`,
        {},
        [],
    )

    return (
        <section className={styles.layout}>
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <div className={styles.header}>
                    <h1>Oeps, er ging iets mis!</h1>
                    <p>
                        Deze pagina heeft pootjes gekregen en is er vandoor
                        gegaan..
                        <br />
                        Naar wat ben je op zoek? Wij helpen je graag verder.
                    </p>
                </div>
                <div className={styles.vacancies}>
                    {loading ? (
                        <PageLoading />
                    ) : (
                        <>
                            {response.spotlight && (
                                <VacancyGrid
                                    items={response.spotlight.slice(0, 3)}
                                />
                            )}
                        </>
                    )}
                </div>
                <div className={styles.text}>
                    <Button to="/" className={["blue"]}>
                        Terug naar huis
                        <ArrowRight />
                    </Button>
                </div>
            </div>
        </section>
    )
}
