import React from "react"
import styles from "./styles.module.scss"

import Introduction from "./introduction"
import Personal from "./personal"
import Skills from "./skills"
import Others from "./others"
import Buttons from "./buttons"
import FloatingButton from "./floating"

import Specialiteit from "../recruiter/specialiteit"
import Skill from "../recruiter/skills"
import Button from "../button"
import ArrowRight from "../../icons/arrow-right"
import OrganizationVideo from "../organization"
import SEO from "../seo"

const RecruiterDetail = ({ recruiter }) => {
    const isMobile = window.innerWidth < 768

    return (
        <div className={styles.wrapper}>
            <SEO
                title={recruiter.seo.title}
                description={recruiter.seo.description}
                image={recruiter.seo.image}
                url={`${process.env.REACT_APP_URL}${window.location.pathname}`}
            />
            {!isMobile && <FloatingButton recruiter={recruiter} />}
            <div className={styles.top}>
                <div
                    className={[
                        "section",
                        "section--black",
                        styles["section--fold"],
                    ].join(" ")}
                >
                    <div className="wrapper">
                        <div className="columns">
                            <div className="column-6">
                                {!isMobile && (
                                    <h2 className={styles["choose--title"]}>
                                        Alleen bij Career Rebels kies <br />
                                        jij zelf je recruiter!
                                    </h2>
                                )}
                                <Specialiteit
                                    content={recruiter.introductie.specialiteit}
                                />
                            </div>
                            <div className="column-6">
                                <Skill recruiter={recruiter} />
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <Buttons recruiter={recruiter} />
                    </div>
                </div>

                <div
                    className={["section", styles["section--padding"]].join(
                        " ",
                    )}
                >
                    {isMobile && (
                        <h2 className={styles["choose--title"]}>
                            Alleen bij Career Rebels kies <br />
                            jij zelf je recruiter!
                        </h2>
                    )}
                    <Introduction recruiter={recruiter} />
                </div>
            </div>

            <div className="section section--light">
                <Personal recruiter={recruiter} />
            </div>

            <div className="section section--purple">
                <Skills recruiter={recruiter} />
            </div>

            {recruiter.video.video && (
                <div className="section section--blue">
                    <div className="wrapper has-content-centered">
                        <h2 className={styles["section--title"]}>
                            Dit zijn wij, Career Rebels
                        </h2>
                        <p className={styles.description}>
                            Aangenaam, Career Rebels, Het eigenwijze en
                            innovatieve bureau met lef en durf, dat net als jij
                            geeft om jouw ontwikkeling. Ons doel is om jou als
                            flex rebel een carrièreboost te geven, zodat jij net
                            als wij van je werk gaat houden #ThankGoditsMonday!
                            We willen écht weten wie jij bent en wat je wil (het
                            CV is daarin wat ons betreft volledig passé). We
                            zijn jouw collega. We praten samen, we lachen samen
                            en we groeien samen.
                        </p>
                        <Button
                            to="/over-career-rebels/"
                            className={["yellow"]}
                        >
                            Ontdek meer
                            <ArrowRight />
                        </Button>
                        <OrganizationVideo />
                    </div>
                </div>
            )}

            <div className="section section--light">
                <Others location={recruiter.locatie[0]} id={recruiter.id} />
            </div>
        </div>
    )
}

export default RecruiterDetail
