import React from "react"
import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import VacancySliderItem from "../../vacancy/item"

export default function VacancySlider({ items }) {
    return (
        <>
            <Swiper
                modules={[Autoplay, Navigation]}
                slidesPerView={3}
                spaceBetween={20}
                speed={2000}
                autoplay={{
                    delay: 5000,
                    stopOnLastSlide: false,
                }}
                loop={true}
            >
                {items &&
                    items.map((item) => (
                        <SwiperSlide key={item.id}>
                            <VacancySliderItem item={item} />
                        </SwiperSlide>
                    ))}
            </Swiper>
        </>
    )
}
