import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"

import Loading from "../loading"
import RecruiterOverview from "../recruiter-overview"

const Others = ({ location, id }) => {
    const [state, setState] = useState(null)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/pages/recruiters`)
            .then((response) => response.json())
            .then((response) => {
                const locations = response.recruiters
                const loc = response.recruiters[location]
                const coworkers = loc.filter((r) => r.id !== id)

                if (coworkers.length < 3) {
                    const all = Object.keys(locations).reduce((acc, city) => {
                        if (city === location) return acc

                        return acc.concat(locations[city])
                    }, [])

                    all.slice(0, 3 - coworkers.length).map((worker) => {
                        return coworkers.push(worker)
                    })
                }
                setState(coworkers)
            })
    }, [id, location])

    return state ? (
        <div className="wrapper">
            <h2 className={styles["section--title"]}>
                Nog steeds niet overtuigd?
            </h2>
            <p className={styles.description}>
                Misschien heb je een klik met een van de andere recruiters.
            </p>
            <RecruiterOverview recruiters={state} showLocation={true} />
        </div>
    ) : (
        <section className="wrapper">
            <Loading />
        </section>
    )
}

export default Others
