import React from "react"

const Logo = (props) => (
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="314px"
        height="162px"
        viewBox="0 0 314 162"
    >
        <g fill="none" fillRule="evenodd">
            <polygon
                fill="#000000"
                transform="translate(174.000000, 114.500000) rotate(93.160000) translate(-174.000000, -114.500000) "
                points="134 -23 214 -23 214 252 134 252"
            />
            <path
                d="M93.3148148,116.057389 L88.2770919,115.784971 L94.2153635,133 L81.8216735,132.332138 L75.8209877,115.108321 L68.5363512,114.712877 L67.5912209,131.602762 L56,130.97005 L58.6748971,84 L95,85.9069225 L93.3148148,116.057389 Z M69.6152263,95.4503228 L69.0713306,105.046449 L83.239369,105.819763 L83.7743484,96.223637 L69.6152263,95.4503228 Z"
                fill="#FFFFFF"
            />
            <polygon
                fill="#FFFFFF"
                points="135.339698 98.7180966 113.79397 97.5611031 113.39598 104.57372 133.358794 105.651226 132.743719 116.576424 112.780905 115.498919 112.364824 122.917808 133.910553 124.074802 133.295477 135 100 133.233598 102.704523 86 136 87.7664023"
            />
            <path
                d="M173.23067,109.255319 L179,109.570713 L177.428143,138 L138,135.836045 L140.634397,89 L174.249304,90.8573217 L173.23067,109.255319 Z M151.391519,100.494368 L150.970015,108.055069 L162.166203,108.668335 L162.587706,101.107635 L151.391519,100.494368 Z M150.434354,117.683354 L149.995288,125.647059 L166.627115,126.523154 L167.066181,118.56821 L150.434354,117.683354 Z"
                fill="#FFFFFF"
            />
            <polygon
                fill="#FFFFFF"
                points="217.384306 102.660837 195.807847 101.503426 195.409457 108.518572 215.392354 109.640642 214.77666 120.56978 194.793763 119.491886 194.377264 126.913451 215.944668 128.070862 215.328974 139 182 137.232961 184.671026 90 218 91.7670393"
            />
            <polygon
                fill="#FFFFFF"
                points="233.222907 130.223145 253 131.280376 252.408569 142 221 140.327496 223.643517 94 235.292915 94.6239393"
            />
            <polygon
                fill="#FFFFFF"
                points="254.658941 131.242708 277.270554 132.460209 277.70383 124.775837 255.092218 123.558336 256.698951 95 291 96.8438963 290.386192 107.757292 267.774579 106.539791 267.395462 113.280158 289.961942 114.497659 288.301049 144 254 142.156104"
            />
            <polygon
                fill="#FFFFFF"
                transform="translate(116.000000, 38.500000) rotate(86.550000) translate(-116.000000, -38.500000) "
                points="85 -75 147 -75 147 152 85 152"
            />
            <path
                d="M31.6816335,25.0446202 C41.1612124,24.4748569 46.7846915,29.3979681 47.2934824,37.7307568 L47.3648917,39.0127243 L40.2953752,39.4400468 L40.2150398,38.0423461 C39.9561812,33.7691211 37.2069248,31.0449402 32.0654583,31.347627 C26.7633209,31.6681189 23.8355413,35.7098775 24.1211784,40.4104249 L24.6746001,49.5978586 C24.9870156,54.7257285 28.1379491,57.9662575 33.6543142,57.6101554 C39.1706794,57.2540533 41.1879909,54.2093805 40.9380585,49.9895709 L40.8487969,48.6007728 L47.891535,48.1912554 L47.9629442,49.4732229 C48.462809,57.7793039 43.9104689,63.3255938 34.0024344,63.9576749 C24.7906402,64.5096332 18.1495792,59.6577423 17.5515267,49.7135918 L17.0338096,41.1671418 C16.5071663,31.8728776 22.1663499,25.6321886 31.6816335,25.0446202 Z"
                fill="#F7AD00"
            />
            <path
                d="M73.4025541,23 L86,59.0620262 L78.7862299,59.5334252 L76.0410883,51.4934528 L63.0349806,52.2616586 L61.2581899,60.572249 L54,61 L62.0932815,23.7158282 L73.4025541,23 Z M73.9711272,45.26051 L68.4275403,29.0845394 L67.7878956,29.0845394 L64.2343143,45.8017459 L73.9711272,45.26051 Z"
                fill="#F7AD00"
            />
            <path
                d="M109.333137,44.5062119 L118,58.3264243 L109.999117,58.7932638 L102.116833,45.5103574 L95.730724,45.8803057 L96.5882872,59.5772019 L89.3628605,60 L87,23.0492093 L103.859329,22.0274472 C111.084756,21.6134574 116.412596,25.9119046 116.8505,32.9409227 C117.206298,38.5870388 114.168334,42.7885947 109.333137,44.5062119 Z M95.2836963,39.5383345 L104.206004,39.0186452 C107.654503,38.8248627 109.82578,36.6227894 109.625074,33.398954 C109.424367,30.1751186 106.997646,28.2461024 103.549147,28.4486931 L94.6268393,28.9683824 L95.2836963,39.5383345 Z"
                fill="#F7AD00"
            />
            <polygon
                fill="#F7AD00"
                points="144.498035 18 144.878193 24.4419244 127.479371 25.4962199 128.009823 34.4309278 143.923379 33.4570447 144.303536 39.8900344 128.38998 40.8639175 128.938114 50.1381443 146.619843 49.0570447 147 55.4900344 122.24558 57 120 19.4742268"
            />
            <polygon
                fill="#F7AD00"
                points="177.489194 17 177.869352 23.4285714 160.47053 24.4910714 161.000982 33.4196429 176.914538 32.4553571 177.294695 38.8839286 161.381139 39.8482143 161.938114 49.1428571 179.619843 48.0625 180 54.5 155.24558 56 153 18.5"
            />
            <path
                d="M204.325479,38.4886748 L213,52.32453 L205.001178,52.7918979 L197.075405,39.4939569 L190.683652,39.8731422 L191.541973,53.5767234 L184.310162,54 L182,17.0073854 L198.874227,16.0285582 C206.106038,15.6052815 211.438586,19.9174123 211.876878,26.9543864 C212.205596,32.5716202 209.17408,36.78675 204.325479,38.4886748 Z M190.272754,33.5239926 L199.202946,33.0037151 C202.654492,32.800895 204.827688,30.6051474 204.626804,27.3776631 C204.42592,24.1501787 201.997054,22.218979 198.545508,22.4129808 L189.606186,22.9332584 L190.272754,33.5239926 Z"
                fill="#F7AD00"
            />
        </g>
    </svg>
)
export default Logo
