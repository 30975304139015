import React from "react"

const LogoNL = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="1080px"
            height="717px"
            viewBox="0 0 1080 717.1"
        >
            <g>
                <g>
                    <rect
                        x="481.4"
                        y="-51.1"
                        transform="matrix(6.059617e-02 0.9982 -0.9982 6.059617e-02 974.1302 -235.9618)"
                        fill="#fff"
                        width="262"
                        height="901.4"
                    />
                    <g>
                        <path
                            fill="#000"
                            d="M351.9,437.3l-16.4,1l25.7,54.1l-40.2,2.4l-25.9-54.1l-23.6,1.4l3.4,55.5l-37.6,2.3l-9.4-154.7l118-7.2L351.9,437.3z
				 M267.6,378.8l1.9,31.6l46-2.8l-1.9-31.6L267.6,378.8z"
                        />
                        <path
                            fill="#000"
                            d="M476.4,366.1l-69,4.2l1.4,23l63.9-3.9l2.2,35.8l-63.9,3.9l1.5,24.3l69-4.2l2.2,35.8l-106.5,6.5l-9.4-154.7l106.5-6.5
				L476.4,366.1z"
                        />
                        <path
                            fill="#000"
                            d="M608.3,383.2l19-1.2l5.7,93.9l-130,7.9l-9.4-154.7l110.9-6.7L608.3,383.2z M533.4,362.6l1.5,25l36.9-2.2l-1.5-25
				L533.4,362.6z M536.9,419.4l1.6,26.3l54.8-3.3l-1.6-26.3L536.9,419.4z"
                        />
                        <path
                            fill="#000"
                            d="M752.5,349.3l-69,4.2l1.4,23l63.9-3.9l2.2,35.8l-63.9,3.9l1.5,24.3l69-4.2l2.2,35.8l-106.5,6.5L643.8,320l106.5-6.5
				L752.5,349.3z"
                        />
                        <path
                            fill="#000"
                            d="M814.6,429l63.9-3.9l2.2,35.8l-101.4,6.2l-9.4-154.7l37.6-2.3L814.6,429z"
                        />
                        <path
                            fill="#000"
                            d="M884.8,424.7l72.5-4.4l-1.5-25.2l-72.5,4.4l-5.7-93.7l110.1-6.7l2.2,35.8l-72.5,4.4l1.3,22.1l72.5-4.4l5.9,96.8
				l-110.1,6.7L884.8,424.7z"
                        />
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <rect
                                x="293"
                                y="-195.2"
                                transform="matrix(0.1751 0.9846 -0.9846 0.1751 502.0734 -242.5629)"
                                fill="#f6ac00"
                                width="205.6"
                                height="747"
                            />
                            <g>
                                <g>
                                    <path
                                        fill="#fff"
                                        d="M120.2,163.2c30.3-5.4,50.3,8.4,55,35.1l0.7,4.1l-22.6,4l-0.8-4.5c-2.4-13.7-12.3-21.5-28.8-18.6
							c-17,3-24.9,17.2-22.2,32.2l5.2,29.5c2.9,16.5,14.3,25.7,32,22.6c18-3.2,23-13.8,20.6-27.4l-0.8-4.5l22.6-4l0.7,4.1
							c4.7,26.6-7.9,46.3-39.6,51.9c-29.5,5.2-52.7-8-58.4-39.8l-4.9-27.4C73.9,190.9,89.9,168.6,120.2,163.2z"
                                    />
                                    <path
                                        fill="#fff"
                                        d="M248.9,142.8l54.6,114l-23.3,4.1l-12-25.5l-41.8,7.4l-2.4,28l-23.3,4.1l11.9-125.9L248.9,142.8z
							 M259.3,215.9l-24.1-51.2l-2.1,0.4l-5,56.4L259.3,215.9z"
                                    />
                                    <path
                                        fill="#fff"
                                        d="M377.4,196l32.6,41.9l-25,4.4l-30-40.5l-20,3.6l7.9,44.4l-22.6,4l-21.3-119.8l52.8-9.4
							c22.6-4,41,8.1,45.1,30.9C400,173.7,392,188.6,377.4,196z M331.3,184.9l27.9-5c10.8-1.9,16.8-9.9,14.9-20.3
							c-1.9-10.5-10.2-15.9-21-13.9l-27.9,5L331.3,184.9z"
                                    />
                                    <path
                                        fill="#fff"
                                        d="M487.4,100.4l3.7,20.6l-56.2,10l5.1,28.6l51.4-9.1L495,171l-51.4,9.1l5.3,29.6l57.2-10.2l3.7,20.6
							l-79.9,14.2l-21.3-120L487.4,100.4z"
                                    />
                                    <path
                                        fill="#fff"
                                        d="M586.6,82.8l3.7,20.6l-56.2,10l5.1,28.6l51.4-9.1l3.7,20.6l-51.4,9.1l5.3,29.6l57.2-10.2l3.7,20.6
							l-79.9,14.2l-21.3-120L586.6,82.8z"
                                    />
                                    <path
                                        fill="#fff"
                                        d="M685.4,141.2l32.6,41.9l-25,4.4l-30-40.5l-20,3.6l7.9,44.4l-22.6,4L606.9,79.3l52.8-9.4
							c22.6-4,41,8.1,45.1,30.9C708,119,700,133.8,685.4,141.2z M639.2,130.1l27.9-5c10.8-1.9,16.8-9.9,14.9-20.3
							c-1.9-10.5-10.2-15.9-21-13.9l-27.9,5L639.2,130.1z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <rect
                    x="517.6"
                    y="481.1"
                    transform="matrix(0.1767 0.9843 -0.9843 0.1767 1078.168 -91.1467)"
                    fill="#4abbc8"
                    width="152"
                    height="235.6"
                />
                <g>
                    <g>
                        <path
                            fill="#fff"
                            d="M522.5,631.8c6.1-1.1,11.5,2.5,12.6,8.8c1.1,6.3-2.7,11.5-8.8,12.6c-6.1,1.1-11.5-2.5-12.6-8.8
				C512.6,638.1,516.4,632.9,522.5,631.8z"
                        />
                        <path
                            fill="#fff"
                            d="M564.5,563.4l29.6,61.5l1.3-0.2L583.9,560l14.8-2.7l14.1,78.7l-26.3,4.7l-29.6-61.5l-1.3,0.2l11.6,64.8
				l-14.8,2.7l-14.1-78.7L564.5,563.4z"
                        />
                        <path
                            fill="#fff"
                            d="M630.5,551.6l11.7,65.2l36.4-6.5l2.4,13.5l-51.3,9.2l-14.1-78.7L630.5,551.6z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default LogoNL
