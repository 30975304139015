import React from "react"
import useFetch from "use-http"

import Layout from "../../components/layout"
import PageLoading from "../../components/page-loading"
import CustomerService from "../../components/customer-service"

export default function ContactPage() {
    const { loading, data } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/customer-service`,
        {},
        [],
    )

    return loading ? (
        <PageLoading />
    ) : (
        <Layout seo={data.yoast}>
            <CustomerService response={data} />
        </Layout>
    )
}
