import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import request from "superagent"

import styles from "./styles.module.scss"
import Layout from "../../components/layout"
// import CaseFilter from "../../components/case-filter"
import PageLoading from "../../components/page-loading"
import Pagination from "../../components/pagination"
import StoriesGrid from "../../components/stories-grid"
import StoriesGridItem from "../../components/stories-grid-item"
import CaseHero from "../../components/case-hero"
import HeadingCta from "../../components/heading-cta"
import { getQueryStringParameters } from "../../helpers/helpers"

class Cases extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageResponse: null,
            pageLoading: false,
            itemResponse: null,
            itemLoading: false,
        }
    }

    componentDidMount() {
        this.getPage().then(() => {
            let search = this.props.location.search
            if (!this.props.location.search) {
                search = JSON.stringify(getQueryStringParameters("filterCase"))
            }
            this.getItems(this.props.match.params, search)
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentParams = this.props.match.params
        const nextParams = nextProps.match.params
        if (
            currentParams.page !== nextParams.page ||
            currentParams.category !== nextParams.category ||
            this.props.location.search !== nextProps.location.search
        ) {
            this.getItems(nextParams, nextProps.location.search)
            if (document.getElementById("items")) {
                let anchor = document.querySelector("#items")
                anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                })
            }
        }
    }

    getPage = () => {
        return request
            .get(`${process.env.REACT_APP_API_URL}/pages/cases`)
            .then((response) => {
                this.setState({
                    pageResponse: response.body,
                    pageLoading: false,
                })
            })
            .catch(() => {
                this.setState({
                    pageResponse: null,
                    pageLoading: false,
                })
            })
    }

    getItems(params, queryString) {
        this.setState({
            itemResponse: null,
            itemLoading: true,
        })
        let url = `${process.env.REACT_APP_API_URL}/cases/overview`
        if (queryString !== "") {
            url = `${process.env.REACT_APP_API_URL}/cases/overview`
        }
        let query = {
            page: params.page,
            filter: queryString ? queryString : null,
        }

        request
            .get(url)
            .query(query)
            .then((response) => {
                this.setState({
                    itemResponse: response.body,
                    itemLoading: false,
                })
            })
            .catch(() => {
                this.setState({
                    itemResponse: false,
                    itemLoading: true,
                })
            })
    }

    render() {
        const { pageResponse, itemResponse } = this.state

        return pageResponse === null ? (
            <PageLoading />
        ) : itemResponse === false ? (
            <Redirect to={`/facts-stories/`} />
        ) : (
            <Layout seo={pageResponse.yoast}>
                <CaseHero
                    variants={["no-margin"]}
                    title={pageResponse.content.title}
                    content={pageResponse.content.content}
                    button={pageResponse.content.button}
                    image={pageResponse.content.image}
                />
                <div id="items">
                    {/*<CaseFilter title={pageResponse.filterTitle} />*/}
                    <div id="overview">
                        {itemResponse ? (
                            <section className={[styles.stories].join(" ")}>
                                <div className="wrapper">
                                    {itemResponse.items ? (
                                        <StoriesGrid multiple={true}>
                                            {itemResponse.items.map(
                                                (item, i) => (
                                                    <StoriesGridItem
                                                        item={item}
                                                        key={i}
                                                    />
                                                ),
                                            )}
                                        </StoriesGrid>
                                    ) : (
                                        <>
                                            <HeadingCta title="Oeps, er zijn geen resultaten gevonden" />
                                            <div className={styles.empty}>
                                                <p>
                                                    Binnenkort valt hier veel
                                                    meer te lezen! Bekijk
                                                    ondertussen onze andere
                                                    Facts & Stories
                                                </p>
                                            </div>
                                        </>
                                    )}
                                    {itemResponse.total > 6 && (
                                        <Pagination
                                            total={itemResponse.total}
                                            perPage={6}
                                            color="black"
                                            currentPage={
                                                this.props.match.params.page ===
                                                    undefined ||
                                                window.location.pathname ===
                                                    "/facts-stories/" ||
                                                window.location.pathname ===
                                                    "/facts-stories"
                                                    ? 1
                                                    : parseInt(
                                                          this.props.match
                                                              .params.page,
                                                      )
                                            }
                                            base={"/facts-stories/pagina"}
                                            queryString={
                                                this.props.location.search
                                            }
                                        />
                                    )}
                                </div>
                            </section>
                        ) : (
                            <PageLoading />
                        )}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Cases
