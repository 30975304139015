import React, { Component } from "react"
import ReactDOM from "react-dom"
import request from "superagent"

import styles from "./styles.module.scss"
import Loading from "../loading"
import ModalForm from "../modal-form"
import Cross from "../../icons/cross"
import RecruiterForm from "../modal-form/recruiter"

class Modal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            response: null,
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
        this.getPopup(this.props.slug)
    }

    getPopup(slug) {
        const param =
            slug === "recruiter-aanmelden"
                ? `?id=${this.props.recruiter.id}`
                : ""

        request
            .get(
                `${process.env.REACT_APP_API_URL}/popups/detail/${slug}${param}`,
            )
            .then((response) => {
                this.setState({
                    response: response.body,
                })
            })
            .catch(() => {
                this.setState({
                    response: false,
                })
            })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)

        this.setState({
            response: null,
        })
    }

    handleClickOutside = (event) => {
        let close = ReactDOM.findDOMNode(this.refs.close)
        if (
            this.refs.content &&
            !this.refs.content.contains(event.target) &&
            event.target !== close
        ) {
            event.preventDefault()
            this.props.close()
        }
    }

    render() {
        const { close, slug } = this.props
        const { recruiter } = this.props || 0
        const { uurtarief } = this.props || false
        const { response } = this.state

        return ReactDOM.createPortal(
            <div className={styles.modal} ref="modal">
                {response === null ? (
                    <Loading color="#fff" />
                ) : response === false ? (
                    <p className={styles["not-found"]} ref="content">
                        Er ging iets fout...
                    </p>
                ) : (
                    <div
                        className={[
                            styles.content,
                            response === null || response === false
                                ? styles["content--loading"]
                                : "",
                            response && response.form === false
                                ? styles["content--simple"]
                                : "",
                        ].join(" ")}
                        ref="content"
                    >
                        <span className={styles.close} onClick={close}>
                            <Cross />
                        </span>
                        {response.form && slug === "recruiter-aanmelden" ? (
                            <RecruiterForm
                                details={response}
                                slug={slug}
                                recruiter={recruiter}
                            />
                        ) : response.form &&
                          slug === "bereken-uitzendtarief" ? (
                            <ModalForm
                                details={response}
                                slug={slug}
                                calculator={{
                                    functie: {
                                        value: uurtarief.function,
                                        error: false,
                                        required: true,
                                    },
                                    uurtarief: {
                                        value: uurtarief.wage,
                                        error: false,
                                        required: true,
                                    },
                                    uren: {
                                        value: uurtarief.hours,
                                        error: false,
                                        required: true,
                                    },
                                }}
                            />
                        ) : response.form ? (
                            <ModalForm details={response} slug={slug} />
                        ) : (
                            <article className={styles.article}>
                                <h2
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{
                                        __html: response.title,
                                    }}
                                />
                                <p
                                    className={styles.description}
                                    dangerouslySetInnerHTML={{
                                        __html: response.description,
                                    }}
                                />
                            </article>
                        )}
                    </div>
                )}
            </div>,
            document.getElementById("careerrebels"),
        )
    }
}

export default Modal
