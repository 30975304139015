export default function Mail(props) {
    return (
        <svg
            width="82px"
            height="82px"
            viewBox="0 0 82 82"
            version="1.1"
            {...props}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fillRule="nonzero" fill="currentColor">
                    <polygon points="82 10.51 82 0 0 0 0 10.51 41 39.33" />
                    <polygon points="41 53.02 0 24.2 0 82 82 82 82 24.2" />
                </g>
            </g>
        </svg>
    )
}
