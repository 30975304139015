import React from "react"

const ArrowLeft = (props) => (
    <svg {...props} width="38px" height="12px" viewBox="0 0 38 12">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="currentColor">
                <polygon
                    transform="translate(18.856500, 6.213000) rotate(-180.000000) translate(-18.856500, -6.213000) "
                    points="0.287 7.672 30.98 7.672 30.98 11.713 37.426 6.191 30.98 0.713 30.98 4.732 0.287 4.732"
                />
            </g>
        </g>
    </svg>
)

export default ArrowLeft
