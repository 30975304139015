import React from "react"
import { NavLink } from "react-router-dom"

import styles from "./styles.module.scss"
import ArrowRightSmall from "../../icons/arrow-right-small"

const FooterNavigation = ({ items }) => {
    return (
        <nav className={styles.navigation}>
            {items.map((item) => (
                <NavLink
                    key={item.slug}
                    to={item.slug}
                    activeClassName={styles.itemIsActive}
                >
                    {item.label}
                    <ArrowRightSmall />
                </NavLink>
            ))}
            <a
                href="https://careerrebels-kk.kentro.nl/KentroKonnekt/Login.aspx"
                rel="noopener noreferrer"
                target="_blank"
            >
                Mijn uren
            </a>
        </nav>
    )
}

export default FooterNavigation
