import React from "react"
import { hydrate, render } from "react-dom"
import { Router } from "react-router-dom"
import "./styles/global.scss"

import Routes from "./components/routes"
import { history } from "./helpers/history"
import { destroyFilterValues } from "./helpers/helpers"

const target = document.querySelector("#careerrebels")
const isIE = !!document?.documentMode

destroyFilterValues()

if (isIE) {
    document.body.classList.add("ie")
}

if (target.hasChildNodes()) {
    hydrate(
        <Router history={history}>
            <Routes />
        </Router>,
        target,
    )
} else {
    render(
        <Router history={history}>
            <Routes />
        </Router>,
        target,
    )
}
