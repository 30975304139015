import styles from "../styles.module.scss"

export default function ChecklistSection({ requirements, offer }) {
    return (
        <div className={styles.checklist}>
            <h2 className={styles.title}>Checklist</h2>
            <h4>Wat wij bieden</h4>
            <div
                className={styles.offer}
                dangerouslySetInnerHTML={{ __html: offer }}
            />
            <h4>Wat wij zoeken</h4>
            <div
                className={styles.requirements}
                dangerouslySetInnerHTML={{ __html: requirements }}
            />
        </div>
    )
}
