import React from "react"
import { Helmet } from "react-helmet"
import useFetch from "use-http"

import Layout from "../../components/layout"
import PageLoader from "../../components/page-loading"
import ApplyNowForm from "../../components/apply-now"
import ImageCta from "../../components/image-cta"

export default function ApplyNowPage() {
    const { data, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/signup`,
        {},
        [],
    )

    return loading ? (
        <PageLoader />
    ) : (
        <Layout seo={data.yoast}>
            <Helmet>
                <script
                    src="https://careerrebels.recruitnowcockpit.nl/widgets/enrollment/recruitnow-open-enrollment-widget.js"
                    type="text/javascript"
                />
            </Helmet>
            <ApplyNowForm image={data.image} />
            <ImageCta variants={["blue"]} detail={data.cta} />
        </Layout>
    )
}
