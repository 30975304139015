import React, { useState } from "react"
import copy from "copy-to-clipboard"

import styles from "./dropdown.module.scss"
import Mail from "../../icons/mail"
import WhatsApp from "../../icons/whatsapp"
import Link from "../../icons/link"
import Facebook from "../../icons/facebook"
import LinkedIn from "../../icons/linkedin"
import Share from "../../icons/share"

const Dropdown = ({ name }) => {
    const [active, setActive] = useState(false)
    const [copied, setCopied] = useState(false)

    const toggle = (event) => {
        event.preventDefault()
        setActive(!active)

        setTimeout(() => {
            setActive(false)
        }, 10000)
    }

    const copyToClipboard = (event) => {
        event.preventDefault()
        copy(event.target.getAttribute("href"))
        setCopied(true)

        setTimeout(() => {
            setCopied(false)
        }, 4000)
    }

    return (
        <div className={styles.dropdown}>
            <div className={styles.button} onClick={toggle}>
                <span>{name}</span>
                <Share />
            </div>
            <div className={[styles.list, active && styles.active].join(" ")}>
                <a
                    href={`mailto:?subject=Recruiter van Career Rebels&body=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Mail />
                    Email
                </a>
                <a
                    href={`whatsapp://send?text=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <WhatsApp />
                    Whatsapp
                </a>
                <a
                    href={`${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={copyToClipboard}
                >
                    <Link />
                    Link {` `}
                    {copied && "gekopieerd!"}
                </a>
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Facebook />
                    Facebook
                </a>
                <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LinkedIn />
                    LinkedIn
                </a>
            </div>
        </div>
    )
}

export default Dropdown
