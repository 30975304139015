import React from "react"
import { useForm } from "react-hook-form"

import styles from "./styles.module.scss"

import Modal from "../../modal/modal"
import ArrowRight from "../../../icons/arrow-right"

const Calculator = ({ block }) => {
    const [showForm, setShowForm] = React.useState(false)
    const [values, setValues] = React.useState({
        wage: 10,
        hours: 8,
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm()

    const onSubmit = async () => {
        setShowForm(true)
    }

    const handleClose = () => {
        setShowForm(false)
    }

    const updateWage = (field) => {
        setValues({
            ...values,
            [field]: getValues(field),
        })
    }

    return (
        <div className={styles.grid}>
            <div className={styles.content}>
                <h2
                    className={styles.title}
                    dangerouslySetInnerHTML={{
                        __html: block.title,
                    }}
                />
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: block.content,
                    }}
                />
            </div>
            <div className={styles.calculator}>
                <h3 className={styles.subtitle}>
                    Snel uw uitzendtarief berekenen?
                </h3>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.field}>
                        <label className={styles.label} htmlFor="function">
                            Functie:
                        </label>
                        <input
                            className="input"
                            id="function"
                            placeholder="Functie"
                            {...register("function", { required: true })}
                        />
                        {errors.function && <>Vul een functie in</>}
                    </div>
                    <div className={styles.field}>
                        <label className={styles.label} htmlFor="wage">
                            Bruto uurloon: {parseInt(values.wage).toFixed(2)}{" "}
                            euro
                        </label>
                        <input
                            type="range"
                            id="wage"
                            min={0}
                            max={100}
                            onMouseMove={() => updateWage("wage")}
                            {...register("wage", {
                                min: 0,
                                max: 100,
                                value: values.wage,
                            })}
                        />
                    </div>
                    <div className={styles.field}>
                        <label className={styles.label} htmlFor="hours">
                            Aantal uren per week: {parseInt(values.hours)}
                        </label>
                        <input
                            type="range"
                            id="hours"
                            min={0}
                            max={40}
                            onMouseMove={() => updateWage("hours")}
                            {...register("hours", {
                                min: 0,
                                max: 40,
                                value: values.hours,
                            })}
                        />
                    </div>
                    <div className={styles.field}>
                        <button
                            type="submit"
                            className={[styles.button, "full-width"].join(" ")}
                        >
                            Berekenen <ArrowRight />
                        </button>
                    </div>
                </form>
            </div>
            {showForm && (
                <Modal
                    close={handleClose}
                    slug={block.button.link.split("modal-").pop()}
                    uurtarief={getValues()}
                />
            )}
        </div>
    )
}

export default Calculator
