import React from "react"
import useFetch from "use-http"

import { destroyFilterValues } from "../../helpers/helpers"
import Layout from "../../components/layout"
import HomePageBanner from "../../components/homepage-banner"
import HeadingCta from "../../components/heading-cta"
import VacancyGrid from "../../components/vacancy/grid"
import ImageCta from "../../components/image-cta"
import PageLoading from "../../components/page-loading"
import VacaturesFilter from "../../components/vacancy/filter"

export default function HomePage() {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/home`,
        {},
        [],
    )

    destroyFilterValues()

    return loading ? (
        <PageLoading />
    ) : (
        <Layout seo={response?.yoast}>
            <HomePageBanner
                title={response.pageHero.title}
                image={response.pageHero.image}
                srcset={response.pageHero.srcset}
            />
            <VacaturesFilter facets={response.facets} homepage={true} />
            <section className="section section--yellow">
                <div className="wrapper">
                    <HeadingCta
                        title={response.vacancies.title}
                        button={{
                            text: "Bekijk alle vacatures",
                            link: "/vacatures/",
                        }}
                    />
                    {response.spotlight && (
                        <VacancyGrid items={response.spotlight} />
                    )}
                </div>
            </section>
            <ImageCta
                variants={["purple", "large-image"]}
                detail={response.cta}
            />
        </Layout>
    )
}
