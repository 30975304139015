import styles from "./styles.module.scss"
import VacaturesOverviewItem from "../../vacancy/item"
import HeadingCta from "../../heading-cta"

export default function VacanciesBlock({ title, items }) {
    return (
        <div className={styles.block}>
            <HeadingCta title={title} />
            <div className={styles.grid}>
                {items.map((item) => (
                    <VacaturesOverviewItem key={item.id} item={item} />
                ))}
            </div>
        </div>
    )
}
