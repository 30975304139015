import React from "react"

const Instagram = (props) => (
    <svg
        {...props}
        width="102px"
        height="102px"
        viewBox="0 0 102 102"
        version="1.1"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero" fill="currentColor">
                <path d="M0,0 L0,102 L102,102 L102,0 L0,0 Z M90,90 L12,90 L12,12 L90,12 L90,90 Z" />
                <circle cx="77.06" cy="24.94" r="5.85" />
                <path d="M51,73.23 C63.27729,73.23 73.23,63.27729 73.23,51 C73.23,38.72271 63.27729,28.77 51,28.77 C38.72271,28.77 28.77,38.72271 28.77,51 C28.7810267,63.2727197 38.7272803,73.2189733 51,73.23 Z M51,40.23 C56.9207993,40.2300411 61.7225873,45.0259216 61.7299172,50.9467163 C61.7372471,56.8675111 56.9473484,61.675266 51.0265673,61.6899669 C45.1057862,61.7046679 40.2920719,56.9207582 40.27,51 C40.275513,45.0762697 45.0762697,40.275513 51,40.27 L51,40.23 Z" />
            </g>
        </g>
    </svg>
)

export default Instagram
