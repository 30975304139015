import React from "react"
import useFetch from "use-http"

import styles from "./styles.module.scss"
import Layout from "../../components/layout"

export default function KleurentestPage() {
    const { post, response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/assessment`,
        {},
        [],
    )
    const handleClick = async (event) => {
        event.preventDefault()

        await loadToken()
    }

    const loadToken = async () => {
        const res = await post("/generate")

        if (response.ok) {
            window.sessionStorage.setItem("token", res.token)
            window.location.href = `https://test.careerrebels.nl/?token=${res.token}`
        }
    }

    return (
        <Layout
            seo={{
                title: "Kleurentest",
                description: "",
            }}
            yoast={false}
        >
            <section
                className={["section section--yellow", styles.wrapper].join(
                    " ",
                )}
            >
                <div className="wrapper">
                    <div className="columns">
                        <div className="column-6">
                            <h1>Benieuwd naar jouw kleur?</h1>
                            <p>
                                Dit is geen test (er is geen goed of fout). Er
                                zijn geen slechte profielen. De uitkomst is een
                                persoonlijkheidsprofiel. Het geeft het gedrags-
                                en communicatievoorkeursstijlen van iemand weer.{" "}
                            </p>
                            <ul>
                                <li>Het zegt iets over je persoonlijkheid;</li>
                                <li>Hoe graag we communiceren met anderen;</li>
                                <li>Hoe we behandeld willen worden;</li>
                                <li>Hoe effectief je samenwerkt;</li>
                                <li>Welke omgeving je voorkeur heeft;</li>
                                <li>Hoe je gemotiveerd raakt;</li>
                            </ul>
                            <button
                                className={styles.button}
                                onClick={handleClick}
                                disabled={loading}
                            >
                                <span className={styles.content}>
                                    Doe de kleurentest!
                                </span>
                            </button>
                            <p className={styles.notice}>
                                (je wordt binnen enkele seconden doorgestuurd)
                            </p>
                        </div>
                        <div className="column-6">
                            <img
                                src="https://topsecret.careerrebels.nl/resources/uploads/2021/04/davey.png"
                                width={600}
                                height={600}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
