import React from "react"
import styles from "./styles.module.scss"

const StoriesGrid = ({ children, multiple }) => {
    return (
        <div
            className={[
                styles.grid,
                multiple ? styles["grid--multiple"] : "",
            ].join(" ")}
        >
            {children}
        </div>
    )
}

export default StoriesGrid
