import { useEffect } from "react"
import { useLocation } from "react-router"
import useFetch from "use-http"
import Layout from "../../components/layout"
import PageLoading from "../../components/page-loading"
import VacaturesFilter from "../../components/vacancy/filter"
import VacaturesOverview from "../../components/vacancy"
import { paramsToObject } from "../../helpers/helpers"

export default function VacaturesPage() {
    const { search } = useLocation()
    const params = new URLSearchParams(search)
    const { post, data } = useFetch(`${process.env.REACT_APP_API_URL}/cr/v1`)

    async function getPage() {
        const obj = paramsToObject(params)
        await post(`/vacatures`, obj)
    }

    useEffect(() => {
        getPage().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    return !data ? (
        <PageLoading />
    ) : (
        <Layout seo={data.seo}>
            <VacaturesFilter facets={data?.facets} />
            <VacaturesOverview vacancies={data.data} />
        </Layout>
    )
}
