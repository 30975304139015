import React, { Component } from "react"
import request from "superagent"
// styles
import styles from "./styles.module.scss"
// components
import Loading from "../loading"
import InputContainer from "../input-container"
import Button from "../button"
// icons
import ArrowRight from "../../icons/arrow-right"
import ArrowLeft from "../../icons/arrow-left"
import Cross from "../../icons/cross"

class ModalForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            response: null,
            error: null,
            disableSubmit: false,
            step: 1,
            values: {
                ...props.calculator,
            },
        }
    }

    submit = (event) => {
        event.preventDefault()
        let countErrors = 0
        let emptyFields = 0

        Object.keys(this.state.values).forEach((item) => {
            let field = this.state.values[item]
            if (field.value === null && field.required === true) {
                emptyFields++
            }
        })

        Object.keys(this.state.values).forEach((item) => {
            let field = this.state.values[item]
            if (field.error === true) {
                countErrors++
            }
        })

        if (countErrors === 0 && emptyFields === 0) {
            this.setState({
                error: null,
            })
            if (this.state.step !== 1 || this.props.details.steps === 1) {
                //SUBMIT
                this.setState({
                    loading: true,
                    error: null,
                    values: {
                        ...this.state.values,
                        recruiter: this.props.recruiter,
                    },
                })

                request
                    .post(
                        `${process.env.REACT_APP_API_URL}/popups/submit/${this.props.slug}`,
                    )
                    .send(this.state.values)
                    .then((response) => {
                        this.setState({
                            response: response.body.data.response,
                            loading: false,
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            response: null,
                            loading: false,
                        })
                        if (error.response) {
                            this.setState({
                                error: error.response.body.message,
                            })
                        } else {
                            this.setState({
                                error: "Er ging iets mis...",
                            })
                        }
                    })
            } else {
                this.setState({
                    step: 2,
                })
            }
        } else {
            this.setState({
                error: "Sommige velden kloppen niet!",
            })
        }
    }

    back = () => {
        this.setState({
            step: this.state.step - 1,
        })
    }

    saveValue = (field, value, error, required) => {
        // console.table(this.state.values)
        // this.setState({
        //     values: Object.assign(this.state.values, {
        //         [field]: { value: value, error: error, required: required },
        //     }),
        // })
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,
                [field]: {
                    value: value,
                    error: error,
                    required: required,
                },
            },
        })
    }

    render() {
        const { details } = this.props
        const { loading, response, error, step, values } = this.state
        let type = ""

        return (
            <div className={styles.modal}>
                <figure className={styles.image}>
                    <img src={details.image} alt="" />
                </figure>

                <form className={styles.form} onSubmit={this.submit}>
                    {response ? (
                        <article
                            className={[
                                styles.article,
                                styles["article--success"],
                            ].join(" ")}
                        >
                            <h2
                                className={styles.title}
                                dangerouslySetInnerHTML={{
                                    __html: response.title,
                                }}
                            />
                            <p
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: response.description,
                                }}
                            />
                            {response.button.text && (
                                <Button
                                    className={["blue"]}
                                    to={response.button.link}
                                    type={type}
                                >
                                    {response.button.text}
                                    <ArrowRight />
                                </Button>
                            )}
                        </article>
                    ) : (
                        <>
                            {details.steps > 1 && (
                                <div className={styles.steps}>
                                    <span
                                        style={{
                                            width: step === 2 ? "100%" : "50%",
                                        }}
                                    />
                                </div>
                            )}
                            <article className={styles.article}>
                                <h2
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{
                                        __html: details.title,
                                    }}
                                />
                                <p
                                    className={styles.description}
                                    dangerouslySetInnerHTML={{
                                        __html: details.description,
                                    }}
                                />
                            </article>
                            <div className={styles.fields}>
                                {step === 1 || details.steps === 1
                                    ? details.fields[1] &&
                                      details.fields[1].map((field, i) => {
                                          return (
                                              <InputContainer
                                                  key={i}
                                                  save={this.saveValue}
                                                  val={
                                                      values
                                                          ? values[field.name]
                                                          : ""
                                                  }
                                                  label={field.label}
                                                  name={field.name}
                                                  required={field.required}
                                                  variants={[field.size]}
                                                  type={field.type}
                                                  options={field.options}
                                                  placeholder={
                                                      field.placeholder
                                                  }
                                                  regex={
                                                      field.type === "email"
                                                          ? /^(([^<>(),;:\s@"]+([^<>(),;:\s@"]+)*)|(.+))@(([^<>()[.,;:\s@"]+)+[^<>()[,;:\s@]{2,})$/i
                                                          : field.type ===
                                                            "phonenumber"
                                                          ? /^((\+|00(\s|\s?\s?)?)31(\s|\s?\s?)?(\(0\)[\s]?)?|0)[1-9]((\s|\s?\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
                                                          : false
                                                  }
                                              />
                                          )
                                      })
                                    : details.fields[2].map((field, i) => {
                                          return (
                                              <InputContainer
                                                  key={i}
                                                  save={this.saveValue}
                                                  val={
                                                      values
                                                          ? values[field.name]
                                                          : ""
                                                  }
                                                  label={field.label}
                                                  name={field.name}
                                                  required={field.required}
                                                  variants={[field.size]}
                                                  type={field.type}
                                                  options={field.options}
                                                  placeholder={
                                                      field.placeholder
                                                  }
                                                  regex={
                                                      field.type === "email"
                                                          ? /^(([^<>(),;:\s@"]+([^<>(),;:\s@"]+)*)|(.+))@(([^<>()[.,;:\s@"]+)+[^<>()[,;:\s@]{2,})$/i
                                                          : field.type ===
                                                            "phonenumber"
                                                          ? /^((\+|00(\s|\s?\s?)?)31(\s|\s?\s?)?(\(0\)[\s]?)?|0)[1-9]((\s|\s?\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
                                                          : null
                                                  }
                                              />
                                          )
                                      })}
                                {(step === 2 && details.notice) ||
                                (details.steps === 1 && details.notice) ? (
                                    <div className={styles.notice}>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: details.notice,
                                            }}
                                        />
                                    </div>
                                ) : null}
                                <div className={styles["button-container"]}>
                                    {step === 2 && (
                                        <span
                                            className={styles.back}
                                            onClick={this.back}
                                        >
                                            <ArrowLeft />
                                            Vorige
                                        </span>
                                    )}
                                    <Button
                                        className={[
                                            "blue",
                                            loading === true ? "loading" : "",
                                        ]}
                                        type="submit"
                                    >
                                        {loading ? (
                                            <Loading color="white" />
                                        ) : (
                                            <>
                                                {step === 2 ||
                                                details.steps === 1
                                                    ? details.button
                                                    : "Volgende!"}
                                                <ArrowRight />
                                            </>
                                        )}
                                    </Button>
                                </div>
                                {error && (
                                    <div className={styles.error}>
                                        <Cross />
                                        {error}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </form>
            </div>
        )
    }
}

export default ModalForm
