import React from "react"
import styles from "./styles.module.scss"

import CustomerServiceSidebar from "../customer-service-sidebar"
import CustomerServiceContent from "../customer-service-content"
import PageLoading from "../page-loading"

const CustomerService = ({ response }) => {
    return response === null ? (
        <PageLoading />
    ) : (
        <section className={styles.layout}>
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <CustomerServiceSidebar />
                <CustomerServiceContent content={response} />
            </div>
        </section>
    )
}

export default CustomerService
