import { Helmet } from "react-helmet"

export default function JobPosting({ data }) {
    const schema = {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        title: data.title,
        description: data.description,
        datePosted: data.datePosted,
        validThrough: data.validThrough,
        directApply: true,
        employmentType: data.employmentType,
        hiringOrganization: {
            "@type": "Organization",
            name: "Career Rebels",
            sameAs: "https://careerrebels.nl/",
        },
        jobLocation: {
            "@type": "Place",
            address: {
                "@type": "PostalAddress",
                addressLocality: data.city,
                addressCountry: "NL",
            },
        },
        baseSalary: {
            "@type": "MonetaryAmount",
            currency: "USD",
            value: {
                "@type": "QuantitativeValue",
                minValue: data.salary.minValue,
                maxValue: data.salary.maxValue,
                unitText: "MONTH",
            },
        },
    }

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    )
}
