import React from "react"
import useFetch from "use-http"

import Layout from "../../components/layout"
import PageLoader from "../../components/page-loading"
import Wizard from "../../components/wizard"

export default function WizardPage() {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/wizard/details`,
        {},
        [],
    )

    return loading ? (
        <section className="wizard">
            <PageLoader color="#fff" />
        </section>
    ) : (
        <Layout seo={response.yoast}>
            <section className="wizard">
                <div className="wrapper">
                    <Wizard details={response} />
                </div>
            </section>
        </Layout>
    )
}
