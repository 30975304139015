import React from "react"
import styles from "./styles.module.scss"

import ReadyToRock from "../../icons/ready-to-rock"
import Ambition from "../../icons/ambition"
import ArrowDownOrange from "../../icons/orange-arrows"
import LetsGo from "../../icons/lets-go"

const HomepageBanner = ({ image, srcset }) => {
    return (
        <div className={styles.container}>
            <div
                className={[styles.wrapper, "wrapper", "wrapper--large"].join(
                    " ",
                )}
            >
                <div className={styles.ambition}>
                    <Ambition />
                    <img srcSet={srcset} src={image} alt="" />
                </div>
                <ArrowDownOrange className={styles.arrows} />
                <ReadyToRock className={styles.text} />
                <LetsGo className={styles.go} />
            </div>
        </div>
    )
}

export default HomepageBanner
