import React from "react"
import useFetch from "use-http"

import Layout from "../../components/layout"
import ContentImage from "../../components/content-image"
import Team from "../../components/team"
// import OurVacancies from "../../components/our-vacancies"
import PageLoader from "../../components/page-loading"

export default function AboutPage() {
    const { data, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/paginas/about`,
        {},
        [],
    )

    return loading ? (
        <PageLoader />
    ) : (
        <Layout seo={data.yoast}>
            <ContentImage
                title={data.title}
                image={data.image}
                content={data.content}
            />
            <Team title={data.team.title} categories={data.team.categories} />
            {/*<OurVacancies details={data.vacancies} items={data.vacatures} />*/}
        </Layout>
    )
}
