import { useState } from "react"
import styles from "./styles.module.scss"
import { classnames } from "../../helpers/classnames"

export default function Collapse({ content }) {
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.wrapper}>
            <div
                className={classnames(
                    styles.content,
                    open ? styles.open : styles.closed,
                )}
            >
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className={styles.expand} onClick={() => setOpen(!open)}>
                ...lees {open ? "minder" : "meer"}
            </div>
        </div>
    )
}
