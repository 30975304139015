import React from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.scss"
import ArrowRight from "../../icons/arrow-right"

const Item = ({ recruiter, showLocation = false }) => {
    return (
        <Link
            to={{
                pathname: `/recruiters/${recruiter.name.toLowerCase()}/`,
                state: {
                    recruiter: recruiter,
                },
            }}
            className={[
                styles.item,
                recruiter.afwezig ? styles["item--missed"] : "",
            ].join(" ")}
        >
            <figure className={styles.image}>
                <div
                    className={styles.background}
                    style={{
                        backgroundImage: `url(${recruiter.introductie.afbeelding})`,
                        backgroundBlendMode: recruiter.afwezig
                            ? "luminosity"
                            : "none",
                    }}
                />
                {recruiter.afwezig && (
                    <span className={styles.afwezig}>Op vakantie!</span>
                )}
            </figure>
            <header className={styles.heading}>
                <span>{recruiter.fullname}</span>
            </header>
            <div className={styles.content}>
                {showLocation && (
                    <span className={styles.sub}>{recruiter.locatie}</span>
                )}
                <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: recruiter.introductie.specialiteit,
                    }}
                />
                <span className={styles.more}>
                    Bekijk recruiter
                    <ArrowRight />
                </span>
            </div>
        </Link>
    )
}

export default Item
