import styles from "./styles.module.scss"

export default function TitleBlocked({ content }) {
    return (
        <div className={styles.block}>
            <div
                className={styles.inner}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    )
}
