import React from "react"

const Link = (props) => (
    <svg
        {...props}
        width="112px"
        height="112px"
        viewBox="0 0 112 112"
        version="1.1"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero" fill="currentColor">
                <polygon points="39.13 94.29 17.71 72.87 51.66 38.93 67.42 54.69 75.9 46.2 51.66 21.96 0.74 72.87 39.13 111.26 53.37 97.02 44.88 88.53" />
                <polygon points="72.87 0.74 58.63 14.98 67.12 23.47 72.87 17.71 94.29 39.13 60.34 73.07 44.63 57.35 36.14 65.84 60.34 90.04 111.26 39.13" />
            </g>
        </g>
    </svg>
)

export default Link
