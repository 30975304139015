import styles from "./styles.module.scss"
import VacaturesOverviewItem from "./item"

export default function VacancyGrid({ items }) {
    return (
        <div className={styles.grid}>
            {items.map((item) => (
                <VacaturesOverviewItem key={item.id} item={item} />
            ))}
        </div>
    )
}
