import React from "react"
import styles from "./styles.module.scss"

import ContentSimple from "../content-simple"
import FaqTabs from "../faq-tabs"
import Button from "../button"
import ArrowRight from "../../icons/arrow-right"

const Content = (props) => {
    const { content, title, button } = props.content
    const categories = props.content.categories

    const slug = button.link.endsWith("/") ? button.link : button.link + "/"

    return (
        <section className={styles.content}>
            <ContentSimple color="#f7ad00" title={title} content={content} />
            {button && button.text && (
                <Button className={["blue"]} to={slug}>
                    {button.text}
                    <ArrowRight />
                </Button>
            )}
            <FaqTabs categories={categories} />
        </section>
    )
}

export default Content
