import React from "react"
import styles from "./styles.module.scss"

const Specialiteit = ({ content }) => {
    return (
        <div className={styles.specialiteit}>
            <h3 className={styles["specialiteit--title"]}>Mijn specialiteit</h3>
            <p
                className={styles["specialiteit--content"]}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </div>
    )
}

export default Specialiteit
