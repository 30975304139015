import { useRef } from "react"
import styles from "./mobile.module.scss"

import { classnames } from "../../helpers/classnames"
import Collapse from "../collapse"
import SollicitatieWidget from "./sections/widget"
import ArrowDown from "../../icons/arrow-down"
import ArrowRight from "../../icons/arrow-right"
import Button from "../button"
import Mail from "../../icons/mail"
import Telephone from "../../icons/telephone"
import Whatsapp from "../../icons/whatsapp"
import Dropdown from "../button/dropdown"

export default function MobileVacatureDetail({ vacancy }) {
    const widgetRef = useRef()
    const recruiterRef = useRef()
    const { content, details, recruiter } = vacancy

    const handleScrollTo = (event) => {
        event.preventDefault()
        const l = event.currentTarget.getAttribute("data-link")
        const el = document.getElementById(l)

        if (el) {
            window.scrollTo({
                left: 0,
                top: el.offsetTop - 20,
                behavior: "smooth",
            })
        }
    }

    const handleSolliciteren = (event) => {
        event.preventDefault()

        window.scrollTo({
            top: widgetRef.current.offsetTop - 70,
            left: 0,
            behavior: "smooth",
        })
    }

    const handleRecruiter = (event) => {
        event.preventDefault()

        window.scrollTo({
            top: recruiterRef.current.offsetTop - 20,
            left: 0,
            behavior: "smooth",
        })
    }

    return (
        <>
            <section className={styles.intro}>
                <div
                    className={styles.hero}
                    style={{
                        backgroundImage: `url("${vacancy.image}")`,
                    }}
                />
                <div className={classnames("wrapper", styles.wrapper)}>
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: vacancy.title }}
                    />
                    <div className={styles.details}>
                        <div>
                            Salaris:{` `}
                            <span>{details.salaris}</span>
                        </div>
                        <div>
                            Dienstverband:{` `}
                            <span>{details.dienstverband}</span>
                        </div>
                        <div>
                            Werkuren:{` `}
                            <span>{details.werkuren}</span>
                        </div>
                        <div>
                            Locatie:{` `}
                            <span>{details.locatie}</span>
                        </div>
                        <div>
                            Datum geplaatst: {` `}
                            <span>{details.datum_geplaatst}</span>
                        </div>
                        <div>
                            Geldig tot: {` `}
                            <span>{details.geldig_tot}</span>
                        </div>
                        <div>
                            Branche: {` `}
                            <span>{details.branche}</span>
                        </div>
                    </div>
                    <div className={styles.quick}>
                        <h3>Snel naar...</h3>
                        <div className={styles.links}>
                            <div className={styles.link}>
                                <ArrowDown />
                                <span
                                    onClick={handleScrollTo}
                                    data-link="beschrijving"
                                >
                                    Beschrijving
                                </span>
                            </div>
                            <div className={styles.link}>
                                <ArrowDown />
                                <span
                                    onClick={handleScrollTo}
                                    data-link="client"
                                >
                                    Opdrachtgever
                                </span>
                            </div>

                            <div className={styles.link}>
                                <ArrowDown />
                                <span
                                    onClick={handleScrollTo}
                                    data-link="requirements"
                                >
                                    Vaardigheden
                                </span>
                            </div>
                            <div className={styles.link}>
                                <ArrowDown />
                                <span
                                    onClick={handleScrollTo}
                                    data-link="offer"
                                >
                                    Wat bieden wij
                                </span>
                            </div>

                            <div className={styles.link}>
                                <ArrowDown />
                                <span onClick={handleSolliciteren}>
                                    Solliciteren
                                </span>
                            </div>
                            <div className={styles.link}>
                                <ArrowDown />
                                <span
                                    onClick={handleScrollTo}
                                    data-link="recruiter"
                                >
                                    Contact recruiter
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="beschrijving"
                    className={classnames("wrapper", styles.function)}
                >
                    <h2>Wat ga je doen?</h2>
                    <div className="wrap-collabsible">
                        <Collapse content={content.function_description} />
                    </div>
                    <div className="flex flex-col gap-1">
                        <Dropdown name="Delen" />
                        <Button
                            to="/"
                            className={[
                                "wide",
                                "blue",
                                "space-between",
                                styles["floating--button"],
                            ]}
                            onClick={handleSolliciteren}
                        >
                            Solliciteer <ArrowRight />
                        </Button>
                        <Button
                            to="/"
                            className={["wide", "transparent-blue"]}
                            onClick={handleRecruiter}
                        >
                            Contact recruiter <ArrowRight />
                        </Button>
                    </div>
                </div>
            </section>

            <section className="section section--yellow-light">
                <div id="offer" className={classnames("wrapper", styles.offer)}>
                    <h2>Wat bieden wij?</h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: content.offer_description,
                        }}
                    />
                </div>
            </section>

            <section className="section section--blue">
                <div
                    id="requirements"
                    className={classnames("wrapper", styles.requirements)}
                >
                    <h2>Wat zoeken wij?</h2>
                    <div
                        className="mb-8"
                        dangerouslySetInnerHTML={{
                            __html: content.requirements_description,
                        }}
                    />
                    <div className="flex flex-col">
                        <Button
                            to="/"
                            className={[
                                "wide",
                                "yellow",
                                "space-between",
                                styles["floating--button"],
                            ]}
                            onClick={handleSolliciteren}
                        >
                            Solliciteer <ArrowRight />
                        </Button>
                        <Button
                            to="/"
                            className={["wide", "transparent-white"]}
                            onClick={handleRecruiter}
                        >
                            Contact recruiter <ArrowRight />
                        </Button>
                    </div>
                </div>
            </section>

            <section className="section section--light">
                <div
                    id="client"
                    className={classnames("wrapper", styles.client)}
                >
                    <h2>Over de opdrachtgever</h2>
                    <div className="wrap-collabsible">
                        <Collapse content={content.client_description} />
                    </div>
                </div>
            </section>

            {!vacancy.closed && (
                <div id="solliciteer" ref={widgetRef}>
                    <SollicitatieWidget vacancyId={vacancy.recruitNow.id} />
                </div>
            )}

            <section className="section section--purple">
                <div
                    id="recruiter"
                    ref={recruiterRef}
                    className={classnames("wrapper", styles.recruiter)}
                >
                    <h2>Contact met de recruiter</h2>
                    <div className={styles.recruiterDetails}>
                        <div className="flex gap-1 mt-4">
                            <Mail />
                            <a href={`mailto:${recruiter.emailaddress}`}>
                                {recruiter.emailaddress}
                            </a>
                        </div>
                        <div className="flex gap-1 mt-4">
                            <Telephone />
                            <a href={`tel:${recruiter.phonenumber}`}>
                                {recruiter.phonenumber}
                            </a>
                        </div>
                        <div className="flex gap-1 mt-4">
                            <Whatsapp />
                            <a href={`https://wa.me/${recruiter.whatsapp}`}>
                                {recruiter.whatsapp}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section section--light">
                <div className="wrapper">
                    <h2>Dit zijn wij, Career Rebels</h2>
                    <p className="pt-4">
                        Aangenaam, Career Rebels, Het eigenwijze en innovatieve
                        bureau met lef en durf, dat net als jij geeft om jouw
                        ontwikkeling. Ons doel is om jou als flex rebel een
                        carrièreboost te geven, zodat jij net als wij van je
                        werk gaat houden #ThankGoditsMonday! We willen écht
                        weten wie jij bent en wat je wil (het CV is daarin wat
                        ons betreft volledig passé). We zijn jouw collega. We
                        praten samen, we lachen samen en we groeien samen.
                    </p>
                </div>
            </section>
        </>
    )
}
