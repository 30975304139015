export const getQueryStringParameters = (localStorageItem) => {
    let values = []
    Object.keys(window.localStorage).filter((item) => {
        if (item.includes(localStorageItem)) {
            let value = JSON.parse(window.localStorage.getItem(item))
            let name = item.replace(localStorageItem, "").toLowerCase()
            let filter = { name: name, values: value }
            values.push(filter)
        }
        return false
    })
    let query = ""
    values.map((filter, i, values) => {
        filter.values.map((item, j, arr) => {
            if (arr.length - 1 === j) {
                query += `${filter.name}[]=${item.slug}`
            } else {
                query += `${filter.name}[]=${item.slug}&`
            }
            return false
        })
        if (values.length - 1 !== i) {
            query += "&"
        }
        return false
    })

    if (localStorageItem === "filterVacancy") {
        if (window.localStorage.getItem("term")) {
            query += `&term=${window.localStorage.getItem("term")}`
        }
        if (window.localStorage.getItem("distance")) {
            query += `&distance=${
                JSON.parse(window.localStorage.getItem("distance")).slug
            }`
        }
    }

    return query
}

export const destroyFilterValues = () => {
    window.localStorage.removeItem("term")
    window.localStorage.removeItem("coords")
    window.localStorage.removeItem("filterVacancyWhat")
    window.localStorage.removeItem("filterVacancyTime")
    window.localStorage.removeItem("filterVacancyEmployment")
    window.localStorage.removeItem("distance")
    window.localStorage.removeItem("filterCaseCategory")
}

export function paramsToObject(params) {
    const result = {}

    for (const [key, value] of params) {
        if (result[key] === undefined) {
            result[key] = [value]
        } else {
            result[key] = [...result[key], value]
        }
    }

    return result
}
