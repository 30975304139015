import React from "react"
import styles from "./styles.module.scss"
import Item from "./item"

const RecruiterOverview = ({ recruiters, showLocation = false }) => {
    return (
        <div className={styles.overview}>
            {recruiters.map((recruiter, index) => (
                <Item
                    key={index}
                    recruiter={recruiter}
                    showLocation={showLocation}
                />
            ))}
        </div>
    )
}

export default RecruiterOverview
