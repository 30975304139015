import React from "react"
import styles from "./styles.module.scss"

const ContentVideo = ({ title, video, type }) => {
    return (
        <section className={["section", `section--${type}`].join(" ")}>
            <div className={["wrapper", styles.wrapper].join(" ")}>
                <h2 className="section-title">{title}</h2>
                <div
                    className="video"
                    dangerouslySetInnerHTML={{
                        __html: video,
                    }}
                />
            </div>
        </section>
    )
}

export default ContentVideo
