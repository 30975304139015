import styles from "./styles.module.scss"
import Button from "../../button"
import Mail from "../../../icons/mail"
import Telephone from "../../../icons/telephone"
import Whatsapp from "../../../icons/whatsapp"

export default function RecruiterBlock({ title, content, recruiter, button }) {
    return (
        <div className={styles.recruiter}>
            <div
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className={styles.author}>
                <div className={styles.recruiterDetails}>
                    <div className="flex gap-1 mt-4">
                        <Mail />
                        <a href={`mailto:${recruiter.emailaddress}`}>
                            {recruiter.emailaddress}
                        </a>
                    </div>
                    <div className="flex gap-1 mt-4">
                        <Telephone />
                        <a href={`tel:${recruiter.phonenumber}`}>
                            {recruiter.phonenumber}
                        </a>
                    </div>
                    <div className="flex gap-1 mt-4">
                        <Whatsapp />
                        <a href={`https://wa.me/${recruiter.whatsapp}`}>
                            {recruiter.whatsapp}
                        </a>
                    </div>
                    <Button className={["yellow"]} to={button.link}>
                        {button.text}
                    </Button>
                </div>
                <div>
                    <img
                        src={recruiter.image}
                        srcSet={recruiter.srcSet}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}
