import React from "react"

const CheckCircle = ({ fill = "#fdcf7a", check = "#000" }) => (
    <svg x="0px" y="0px" viewBox="0 0 512 512">
        <ellipse cx={256} cy={256} rx={256} ry={255.8} fill={fill} />
        <path
            d="M235.5 392.1L114.4 297.8 148.8 253.6 223.2 311.5 345.8 134.5 391.9 166.4z"
            fill={check}
        />
    </svg>
)

export default CheckCircle
