import React, { Component } from "react"
import { history } from "../../helpers/history"

import styles from "./styles.module.scss"
import ArrowRightSmall from "../../icons/arrow-right-small"

class Pagination extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pages: 1,
            showPages: [],
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.totalPages()
    }

    componentDidMount() {
        this.totalPages()
    }

    totalPages() {
        let pages = Math.ceil(this.props.total / this.props.perPage) + 1
        let showPages = []
        const currentPage = this.props.currentPage
        Array.apply(0, Array(pages)).map((item, i) => {
            if (currentPage < 4) {
                showPages = [1, 2, 3, 4, 5]
            } else if (pages - currentPage < 5) {
                showPages = [...Array(pages).keys()].slice(
                    Math.max(pages - 5, 1),
                )
            } else if (currentPage >= 4) {
                if (
                    currentPage - 2 === i ||
                    currentPage - 1 === i ||
                    currentPage + 2 === i ||
                    currentPage + 1 === i ||
                    currentPage === i
                ) {
                    showPages.push(i)
                }
            }
            return false
        })
        this.setState({
            pages: pages - 1,
            showPages: showPages,
        })
    }

    render() {
        const { base, currentPage, className, queryString, color } = this.props
        const { pages, showPages } = this.state

        let previousLink = `${base}/${currentPage - 1}/${queryString}`
        if (currentPage - 1 === 1) {
            previousLink = base.replace("/pagina", queryString)
        }

        return (
            <div
                className={[
                    className ? className : "",
                    styles["pagination"],
                    "pagination",
                    color ? styles[`pagination--${color}`] : "",
                ].join(" ")}
            >
                {currentPage > 1 && (
                    <a
                        href={previousLink}
                        onClick={(e) => {
                            e.preventDefault()
                            history.push(previousLink)
                        }}
                        className={[styles.button, styles["button--prev"]].join(
                            " ",
                        )}
                    >
                        <ArrowRightSmall />
                    </a>
                )}
                {pages && (
                    <ul className={styles.pages}>
                        {/* {currentPage > 4 && <li>...</li>} */}
                        {Array.apply(0, Array(pages)).map((item, i) => {
                            let page = i + 1

                            let link = `${base}/${page}/${queryString}`
                            if (i === 0) {
                                link = base.replace("/pagina", queryString)
                            }

                            if (showPages.includes(page)) {
                                return (
                                    <li
                                        key={i}
                                        className={
                                            currentPage === page
                                                ? styles["page-active"]
                                                : ""
                                        }
                                    >
                                        <a
                                            href={link}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                history.push(link)
                                            }}
                                        >
                                            {page}
                                        </a>
                                    </li>
                                )
                            }
                            return false
                        })}
                        {/* {showPages.pop() < pages - 1 && <li>...</li>} */}
                    </ul>
                )}
                {currentPage < this.state.pages && (
                    <a
                        href={`${base}/${currentPage + 1}${queryString}`}
                        className={styles.button}
                        onClick={(e) => {
                            e.preventDefault()
                            history.push(
                                `${base}/${currentPage + 1}${queryString}`,
                            )
                        }}
                    >
                        <ArrowRightSmall />
                    </a>
                )}
            </div>
        )
    }
}

export default Pagination
