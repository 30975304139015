import { Link } from "react-router-dom"
import { isMobile } from "react-device-detect"
import styles from "./styles.module.scss"
import ArrowRight from "../../icons/arrow-right"

export default function VacaturesOverviewItem({ item }) {
    return (
        <Link to={`/${item.url}`} className={styles.item}>
            <figure className={styles.image}>
                <div
                    className={styles.background}
                    style={{ backgroundImage: `url(${item.image})` }}
                />
            </figure>
            <header className={styles.heading}>
                <span dangerouslySetInnerHTML={{ __html: item.title }} />
            </header>
            <div className={styles.content}>
                <div className={styles.sub}>
                    <span
                        className={styles.region}
                        dangerouslySetInnerHTML={{
                            __html: item.category || "",
                        }}
                    />
                    <span
                        className={styles.region}
                        dangerouslySetInnerHTML={{
                            __html: item.location || "",
                        }}
                    />
                </div>
                {!isMobile && (
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: item.excerpt }}
                    />
                )}
                <span className={styles.more}>
                    Bekijk vacature
                    <ArrowRight />
                </span>
            </div>
        </Link>
    )
}
