import React from "react"
import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import styles from "./styles.module.scss"

const SalesCases = ({ title, description, items }) => {
    return (
        <div className={styles.cases}>
            <div className={styles.row}>
                <h2
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
            <div className={styles.row}>
                <Swiper
                    modules={[Autoplay, Navigation]}
                    slidesPerView={1}
                    spaceBetween={50}
                    speed={2000}
                    autoplay={{
                        delay: 5000,
                        stopOnLastSlide: false,
                    }}
                    loop={true}
                >
                    {items &&
                        items.map((item, i) => (
                            <SwiperSlide key={i}>
                                <div className={styles.item}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </div>
    )
}

export default SalesCases
