import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import useFetch from "use-http"
import PageLoading from "../../components/page-loading"
import Layout from "../../components/layout"
import VacatureDetail from "../../components/vacancy/detail"
import { isMobile } from "react-device-detect"
import MobileVacatureDetail from "../../components/vacancy/mobile"
import JobPosting from "../../components/vacancy/sections/jobPosting"

export default function VacaturesSinglePage() {
    const { slug } = useParams()
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/cr/v1/vacatures/${slug}`,
        {},
        [slug],
    )

    useEffect(() => {
        return () => {
            document.cookie =
                encodeURIComponent("rn_id") +
                "=" +
                encodeURIComponent("hoi") +
                "; max-age=86400; path=/;"
        }
    }, [response])

    return loading ? (
        <PageLoading />
    ) : (
        <Layout seo={response?.seo}>
            <Helmet>
                <script
                    src="https://careerrebels.recruitnowcockpit.nl/jobsite/scripts/jobboard-application-form-v2.js"
                    type="text/javascript"
                    defer
                ></script>
            </Helmet>
            <JobPosting data={response.jobs} />
            {isMobile ? (
                <MobileVacatureDetail vacancy={response.data} />
            ) : (
                <VacatureDetail vacancy={response.data} />
            )}
        </Layout>
    )
}
