import React from "react"

const Logo = (props) => (
    <svg {...props} width="560" height="80" viewBox="0 0 560 80">
        <g fill="none" fillRule="evenodd">
            <path fill="#F7AD00" d="M290.827 0v80H0V0z" />
            <path
                fill="#FFF"
                d="M40.806 15.425c12.005 0 18.712 6.637 18.712 17.206v1.634h-8.95v-1.77c0-5.419-3.247-9.013-9.762-9.013-6.763 0-10.709 4.867-10.709 10.84v11.593c0 6.502 3.731 10.84 10.709 10.84s9.762-3.73 9.762-9.082v-1.758h8.95v1.623c0 10.501-6.166 17.206-18.712 17.206-11.656 0-19.648-6.637-19.648-19.234V34.67c0-11.785 7.654-19.245 19.648-19.245z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M91.554 16.372l13.144 47.425h-9.221l-2.84-10.569H76.1l-2.83 10.57h-9.232l13.144-47.426h14.372zM90.528 45.07l-5.76-21.239h-.811l-5.76 21.24h12.331zM137.185 45.51l9.615 18.287h-9.886l-8.747-17.543h-7.89v17.543h-8.985V16.473h20.876c8.94 0 15.173 5.893 15.173 14.907 0 7.144-4.058 12.293-10.156 14.13zm-16.909-7.392h11.047c4.261 0 7.113-2.636 7.113-6.76s-2.852-6.76-7.113-6.76h-11.047v13.52z"
            />
            <path
                fill="#FFF"
                d="M185.859 16.372v8.135h-22.23v11.268h20.29v8.169h-20.29v11.718h22.636v8.135h-31.563V16.372zM225.053 16.372v8.135h-22.218v11.268h20.29v8.169h-20.29v11.718h22.612v8.135h-31.562V16.372z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M258.971 45.499l9.627 18.298H258.7l-8.748-17.543h-7.89v17.543h-8.95V16.473h20.876c8.95 0 15.184 5.893 15.184 14.907-.034 7.144-4.103 12.293-10.202 14.119zm-16.908-7.38h11.047c4.272 0 7.113-2.637 7.113-6.761 0-4.124-2.841-6.76-7.113-6.76h-11.047v13.52z"
            />
            <path fill="#000" d="M566.143 0v80H290.827V0z" />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M348.316 46.693h-4.96l6.831 16.969h-12.31l-6.898-16.969h-7.237v16.969h-11.497V16.338h36.071v30.355zM323.742 27.29v9.668h14.068V27.29h-14.068z"
            />
            <path
                fill="#FFF"
                d="M387.645 27.29h-21.101v7.031h19.546v10.952h-19.546v7.437h21.101v10.952H355.08V16.338h32.6z"
            />
            <path
                fill="#FFF"
                fillRule="nonzero"
                d="M427.55 34.93h5.816v28.732h-39.769V16.338h33.953V34.93zm-22.455-7.64v7.64h11.272v-7.64h-11.272zm0 17.375v8.045h16.773v-8.045h-16.773z"
            />
            <path
                fill="#FFF"
                d="M472.132 27.29H451.03v7.031h19.546v10.952H451.03v7.437h21.102v10.952h-32.6V16.338h32.6zM489.581 52.71h19.547v10.952h-31.044V16.338h11.497zM511.055 52.71h22.184v-7.707h-22.184V16.338h33.67V27.29h-22.172v6.76h22.173v29.612h-33.67z"
            />
        </g>
    </svg>
)
export default Logo
