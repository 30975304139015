import React from "react"
import styles from "./styles.module.scss"

import ArrowRightSmall from "../../icons/arrow-right-small"
import Telephone from "../../icons/telephone"
import Whatsapp from "../../icons/whatsapp"
import Mail from "../../icons/mail"

export default function Location({ location, type }) {
    const { address, contact } = location

    return (
        <div
            className={[
                styles.list,
                type === "large" ? styles["list--large"] : null,
                type === "footer" ? styles["list--footer"] : null,
            ].join(" ")}
        >
            <address className={styles.address}>
                <span className={styles.name}>
                    <b>{address.name}</b>
                </span>
                <span className={styles.name}>
                    <b>{address.location}</b>
                </span>
                <span>{address.street}</span>
                <span>
                    {address.postalcode} {address.city}
                </span>
                <a
                    href={address.route}
                    className={styles.route}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Routebeschrijving <ArrowRightSmall />
                </a>
            </address>
            <ul className={styles.contact}>
                {contact.phonenumber && (
                    <li>
                        <a href={`tel:${contact.phonenumber}`}>
                            <Telephone />
                            {contact.phonenumber}
                        </a>
                    </li>
                )}
                {contact.whatsapp && (
                    <li>
                        <a href={`tel:${contact.whatsapp}`}>
                            <Whatsapp />
                            {contact.whatsapp}
                        </a>
                    </li>
                )}
                {contact.emailaddress && (
                    <li>
                        <a href={`mailto:${contact.emailaddress}`}>
                            <Mail />
                            {contact.emailaddress}
                        </a>
                    </li>
                )}
            </ul>
        </div>
    )
}
