import React, { Component } from "react"
import styles from "./styles.module.scss"
import ArrowDown from "../../icons/arrow-down"

class InputContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: null,
            value: null,
        }
    }

    classNames = (variants) => {
        let result = []
        result.push(styles["input-container"])
        if (variants) {
            variants.forEach(function (variant) {
                result.push(styles[`input-container--${variant}`])
            })
        }

        if (this.state.error) {
            result.push(styles["input-container--error"])
        } else if (this.state.error === false) {
            result.push(styles["input-container--success"])
        }
        return result.join(" ")
    }

    componentDidMount() {
        if (this.props.type === "select") {
            this.setState({
                error: false,
            })
        }
        if (this.props.val && this.props.val.value) {
            this.checkValue(this.props.val.value).then((values) => {
                if (this.props.save) {
                    this.props.save(
                        this.props.name,
                        this.props.val.value,
                        this.state.error,
                        this.props.required,
                    )
                }
            })
        } else {
            this.props.save(
                this.props.name,
                null,
                this.state.error,
                this.props.required,
            )
        }
    }

    checkValue = (value) => {
        this.setState({
            error: true,
            value: value,
        })
        if (this.props.required) {
            if (value !== "" && !this.props.regex) {
                this.setState({
                    error: false,
                })
            } else if (this.props.regex) {
                let regex = value.match(this.props.regex)
                if (regex) {
                    this.setState({
                        error: false,
                    })
                } else {
                    this.setState({
                        error: true,
                    })
                }
            } else {
                this.setState({
                    error: true,
                })
            }
        } else {
            this.setState({
                error: false,
            })
        }
        return new Promise((resolve, reject) => {
            resolve({ value: value, error: this.state.error })
        })
    }

    change = (event) => {
        this.checkValue(event.currentTarget.value).then((values) => {
            if (this.props.save) {
                this.props.save(
                    this.props.name,
                    values.value,
                    this.state.error,
                    this.props.required,
                )
            }
        })
    }

    render() {
        const {
            label,
            name,
            variants,
            type,
            placeholder,
            options,
            val,
            inputType,
            maxLength,
            required,
        } = this.props

        const { value } = this.state

        return type === "hidden" ? (
            <input
                id={name}
                name={name}
                type="hidden"
                onChange={this.change}
                value={val ? val.value : ""}
            />
        ) : (
            <label className={this.classNames(variants)} htmlFor={name}>
                <span
                    className={[
                        styles["input-container__label"],
                        required && styles.required,
                    ].join(" ")}
                >
                    {label}
                </span>
                {type === "textarea" ? (
                    <textarea
                        id={name}
                        name={name}
                        rows="3"
                        placeholder={placeholder}
                        onChange={this.change}
                        value={val ? val.value : ""}
                        maxLength={maxLength ? maxLength : 300}
                    />
                ) : type === "select" ? (
                    <div className={styles.select}>
                        <span>
                            {value ? value : options ? "Kies..." : "Kies..."}
                        </span>
                        <ArrowDown />
                        <select
                            defaultValue={value ? false : "Kies..."}
                            id={name}
                            name={name}
                            onChange={this.change}
                        >
                            <option
                                value="Kies..."
                                disabled={true}
                                dangerouslySetInnerHTML={{ __html: "Kies..." }}
                            />
                            {options ? (
                                options.map((item, i) => {
                                    return (
                                        <option key={i} value={item.value}>
                                            {item.label}
                                        </option>
                                    )
                                })
                            ) : (
                                <option
                                    disabled={true}
                                    selected={true}
                                    dangerouslySetInnerHTML={{
                                        __html: "Geen opties",
                                    }}
                                />
                            )}
                        </select>
                    </div>
                ) : type === "select2" ? (
                    <div className={styles.select}>
                        <span>
                            {value
                                ? options[value]
                                : options
                                ? "Kies..."
                                : "Kies..."}
                        </span>
                        <ArrowDown />
                        <select
                            defaultValue={value ? false : "Kies..."}
                            id={name}
                            name={name}
                            onChange={this.change}
                        >
                            <option
                                value="Kies..."
                                disabled={true}
                                dangerouslySetInnerHTML={{ __html: "Kies..." }}
                            />
                            {options ? (
                                Object.entries(options).map(([id, label]) => {
                                    return (
                                        <option key={id} value={id}>
                                            {label}
                                        </option>
                                    )
                                })
                            ) : (
                                <option
                                    disabled={true}
                                    selected={true}
                                    dangerouslySetInnerHTML={{
                                        __html: "Geen opties",
                                    }}
                                />
                            )}
                        </select>
                    </div>
                ) : (
                    <input
                        id={name}
                        name={name}
                        type={inputType ? inputType : "text"}
                        onChange={this.change}
                        value={val ? val.value : ""}
                        maxLength={maxLength ? maxLength : 30}
                    />
                )}
            </label>
        )
    }
}

export default InputContainer
