import React from "react"
import useFetch from "use-http"
import styles from "./styles.module.scss"

import Layout from "../../components/layout"
import PageLoading from "../../components/page-loading"
import RecruiterOverview from "../../components/recruiter-overview"
import RecruiterHero from "../../components/recruiter-hero"
import OurVacancies from "../../components/our-vacancies"

export default function RecruitersPage() {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/recruiters`,
        {},
        [],
    )

    return loading ? (
        <PageLoading />
    ) : (
        <Layout seo={response.yoast}>
            <RecruiterHero title={response.title} content={response.content} />
            <section className={styles.recruiters}>
                <div className="wrapper">
                    <h1 className={styles.title}>
                        Kies een recruiter uit jouw regio!
                    </h1>
                    {response.recruiters &&
                        Object.entries(response.recruiters).map(
                            ([location, recruiters]) => (
                                <div key={location}>
                                    <div className={styles.location}>
                                        <h2>{location}</h2>
                                    </div>
                                    <RecruiterOverview
                                        recruiters={recruiters}
                                    />
                                </div>
                            ),
                        )}
                </div>
            </section>
            {!response.vacancies.items && (
                <OurVacancies details={response.vacancies} showImage={false} />
            )}
        </Layout>
    )
}
