import React from "react"
import styles from "./styles.module.scss"

const Skills = ({ recruiter }) => {
    const maxSkills = window.innerWidth < 768 ? 3 : 5

    const soft = recruiter.introductie.soft_skills.slice(0, maxSkills)
    const hard = recruiter.introductie.hard_skills.slice(0, maxSkills)

    return (
        <div className={styles.skills}>
            <div className={styles["skills--column"]}>
                <h3 className={styles["skills--title"]}>Soft Skills</h3>
                <div className={styles["skills--list"]}>
                    {soft.map((skill, i) => (
                        <div key={i} className={styles["skills--list-item"]}>
                            {skill.skill}
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles["skills--column"]}>
                <h3 className={styles["skills--title"]}>Hard Skills</h3>
                <div className={styles["skills--list"]}>
                    {hard.map((skill, i) => (
                        <div key={i} className={styles["skills--list-item"]}>
                            {skill.skill}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Skills
