import React from "react"
import styles from "./styles.module.scss"

import Button from "../button"
import Dropdown from "../button/dropdown"
import ArrowRight from "../../icons/arrow-right"

const Buttons = ({ recruiter }) => {
    return (
        <div className={styles.buttons}>
            <Dropdown name="Delen" />
            <Button
                to="#"
                className={["blue", "space-between"]}
                recruiter={recruiter}
                modal="/modal-recruiter-aanmelden"
            >
                Ik kies voor {recruiter.name} <ArrowRight />
            </Button>
        </div>
    )
}

export default Buttons
