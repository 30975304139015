import React from "react"
import styles from "./styles.module.scss"

import Button from "../button"
import ArrowRight from "../../icons/arrow-right"

const CaseHero = ({ title, content, button, image, variants }) => {
    const classNames = (variants) => {
        let result = []
        result.push(styles["hero"])

        if (variants) {
            variants.forEach(function (variant) {
                result.push(styles[`hero--${variant}`])
            })
        }

        return result.join(" ")
    }

    return (
        <div className={classNames(variants)}>
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <div className={styles.content}>
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    {content && (
                        <article
                            className={styles.description}
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    )}
                    {button && button.text && (
                        <Button className={["blue"]} to={button.link}>
                            {button.text}
                            <ArrowRight />
                        </Button>
                    )}
                </div>
                {image && (
                    <figure>
                        <img src={image} alt={title} className={styles.image} />
                    </figure>
                )}
            </div>
        </div>
    )
}

export default CaseHero
