import React from "react"
import { useForm } from "react-hook-form"
import { useFetch } from "use-http"

import { classnames } from "../../../helpers/classnames"

import styles from "./styles.module.scss"
import ArrowRight from "../../../icons/arrow-right"

const ContactForm = ({
    id,
    block,
    title,
    description,
    description2,
    fields,
    button = "Verzenden",
    submitText = "",
}) => {
    const { register, handleSubmit, formState } = useForm({
        fields,
    })

    const { post } = useFetch(
        `${process.env.REACT_APP_API_URL}/forms`,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        },
        [],
    )

    const onSubmit = async (b, event) => {
        if (formState.isSubmitting || formState.isSubmitted) {
            return
        }

        const body = {
            id: id,
            block: block,
            ...b,
        }

        await post(`/submit/contact-form`, body)
    }

    return !fields ? (
        <div className={styles.block}>Loading</div>
    ) : (
        <div className={styles.block}>
            <h2>{title}</h2>
            <div className={styles.columns}>
                <div
                    className={styles["col-1"]}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
                {description2 && (
                    <div
                        className={styles["col-2"]}
                        dangerouslySetInnerHTML={{ __html: description2 }}
                    />
                )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                {fields.map((field, index) => (
                    <div key={index} className={styles.field}>
                        <label htmlFor={field.name}>{field.label}</label>
                        {field.type === "textarea" ? (
                            <textarea
                                {...register(`${field.name}`)}
                                required={field.required === "true"}
                                placeholder={field.placeholder}
                                autoComplete="off"
                                maxLength={300}
                                rows={3}
                            />
                        ) : field.type === "select" ? (
                            <select
                                {...register(`${field.name}`)}
                                defaultValue="Kies..."
                                required={field.required === "true"}
                            >
                                <option
                                    value="Kies..."
                                    disabled={true}
                                    dangerouslySetInnerHTML={{
                                        __html: "Kies...",
                                    }}
                                />
                                {field.options &&
                                    field.options.map((option) => (
                                        <option
                                            key={option.label}
                                            value={option.label}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                            </select>
                        ) : (
                            <input
                                {...register(`${field.name}`)}
                                required={field.required === "true"}
                                placeholder={field.placeholder}
                                type={field.type}
                                maxLength={30}
                            />
                        )}
                        <span
                            className={classnames(
                                "inline-block",
                                field.info_text && "block",
                            )}
                            dangerouslySetInnerHTML={{
                                __html: field.info_text || "&nbsp;",
                            }}
                        />
                    </div>
                ))}
                <div>
                    <button
                        className="button bg-blue-light text-white hover-bg-blue-lighter disabled px-8"
                        disabled={formState.isSubmitted}
                    >
                        {button}
                        <ArrowRight />
                    </button>
                    {formState.isSubmitSuccessful && (
                        <p className="p-4">
                            Yes! Je bericht is verzonden. We nemen nog vandaag
                            contact met je op!
                        </p>
                    )}
                </div>
            </form>
        </div>
    )
}

export default ContactForm
