import React from "react"
import styles from "./styles.module.scss"

import StoriesGrid from "../stories-grid"
import StoriesGridItem from "../stories-grid-item"
import HeadingCta from "../heading-cta"
import Loading from "../loading"

const Stories = ({
    title,
    items,
    button,
    background = "yellow",
    multiple,
    grayscale,
}) => {
    return (
        <section
            className={[
                styles.stories,
                background ? styles[`stories--${background}`] : "",
                grayscale ? styles[`stories--grayscale`] : "",
            ].join(" ")}
        >
            <div className="wrapper">
                {title !== false && (
                    <HeadingCta
                        title={title}
                        button={button.text ? button : false}
                    />
                )}
                {items ? (
                    <StoriesGrid multiple={multiple}>
                        {items.map((item, i) => (
                            <StoriesGridItem item={item} key={i} />
                        ))}
                    </StoriesGrid>
                ) : (
                    <Loading />
                )}
            </div>
        </section>
    )
}

export default Stories
