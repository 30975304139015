import { isMobile } from "react-device-detect"
import styles from "../styles.module.scss"
import { classnames } from "../../../helpers/classnames"

export default function ClientSection({ description, video }) {
    return (
        <section id="client" className="section section--purple">
            <div className={classnames("wrapper px-8", styles.wrapper)}>
                {video ? (
                    <div className={styles.video}>
                        <div>
                            <h2 className={styles.title}>Over de werkgever</h2>
                            <div
                                className={styles.article}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </div>
                        {!isMobile && (
                            <div className={styles.frame}>
                                <iframe
                                    width="600px"
                                    height="338"
                                    src={video}
                                    title="Strooming"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen={true}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <h2 className={styles.title}>Over de werkgever</h2>
                        <div
                            className={styles.article}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </>
                )}
            </div>
        </section>
    )
}
