import React from "react"
import styles from "./styles.module.scss"
import Loading from "../loading"

const PageLoading = ({ color = "#000" }) => {
    return (
        <section className={styles.loading}>
            <Loading color={color} />
        </section>
    )
}

export default PageLoading
