export default function ChevronRight(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            {...props}
        >
            <path
                fill="currentColor"
                d="M8 2.9l8 8-2.1 2.2-5.9-5.9-5.9 5.9-2.1-2.2z"
            />
        </svg>
    )
}
