import React from "react"
import styles from "./styles.module.scss"

const Video = ({ title, video }) => {
    return (
        <div className={styles.wrapper}>
            <h2
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
                className={styles.video}
                dangerouslySetInnerHTML={{
                    __html: video,
                }}
            />
        </div>
    )
}

export default Video
