import React from "react"
import { NavLink } from "react-router-dom"
import useFetch from "use-http"

import styles from "./styles.module.scss"
import Loading from "../loading"
import ArrowRight from "../../icons/arrow-right"

const HeaderMenuItems = ({ close }) => {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/navigation/horizontal`,
        {},
        [],
    )

    return loading ? (
        <Loading />
    ) : (
        <>
            {response &&
                response.map((item, i) => {
                    let linkColor = "yellow"
                    if (item.slug === "onze-diensten") {
                        linkColor = "purple"
                    }

                    return (
                        <NavLink
                            key={i}
                            className={[
                                styles.item,
                                styles.vacancies,
                                styles[linkColor],
                            ].join(" ")}
                            activeClassName={styles["active-item"]}
                            to={item.slug}
                            onClick={close}
                        >
                            {item.title} <ArrowRight />
                        </NavLink>
                    )
                })}
        </>
    )
}

export default HeaderMenuItems
