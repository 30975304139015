import React, { useState } from "react"

import styles from "./styles.module.scss"
import ArrowDown from "../../icons/arrow-down"

const Questions = ({ items }) => {
    const [active, setActive] = useState(-1)

    const handleClick = (index) => {
        setActive(index)
    }

    return (
        <ul className={styles.accordion}>
            {items &&
                items.map((item, i) => (
                    <li
                        key={i}
                        className={[
                            styles.item,
                            active === i ? styles["item--active"] : "",
                        ].join(" ")}
                    >
                        <div
                            className={styles.heading}
                            onClick={() => handleClick(i)}
                        >
                            <span className={styles.title}>
                                {item.question}
                            </span>
                            <ArrowDown />
                        </div>
                        <div className={styles.content}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                }}
                            />
                        </div>
                    </li>
                ))}
        </ul>
    )
}

export default Questions
