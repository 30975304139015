import React, { Component } from "react"
import { CSSTransition } from "react-transition-group"
import copy from "copy-to-clipboard"

import styles from "./styles.module.scss"
import Button from "../button"

import Share from "../../icons/share"
import Mail from "../../icons/mail"
import Whatsapp from "../../icons/whatsapp"
import Link from "../../icons/link"
import LinkedIn from "../../icons/linkedin"
import Facebook from "../../icons/facebook"

class CaseDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            socialActive: false,
            favoriteActive: false,
            copiedLink: false,
        }
    }

    toggleSocial = (event) => {
        event.preventDefault()
        this.setState({
            socialActive: !this.state.socialActive,
        })
    }

    copyToClipboard = (event) => {
        event.preventDefault()
        copy(event.currentTarget.getAttribute("href"))
        this.setState({
            copiedLink: true,
        })
        setTimeout(() => {
            this.setState({
                copiedLink: false,
            })
        }, 4000)
    }

    render() {
        const { content, intro, quote } = this.props
        const { socialActive, copiedLink } = this.state

        return (
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <aside className={styles.sidebar}>
                    <div className={styles.buttonContainer}>
                        <Button
                            to="/"
                            className={[
                                "border",
                                "blue",
                                "space-between",
                                socialActive ? "active" : "",
                                socialActive
                                    ? "border--light-yellow--active"
                                    : "",
                            ]}
                            onClick={this.toggleSocial}
                        >
                            Delen <Share />
                        </Button>
                    </div>
                    <CSSTransition
                        in={socialActive}
                        timeout={{
                            enter: 200,
                            exit: 200,
                        }}
                        classNames={{
                            enterDone: styles["social--enter-done"],
                        }}
                        unmountOnExit
                        onExited={() => {
                            this.setState({
                                socialActive: false,
                            })
                        }}
                    >
                        {() => (
                            <ul className={styles.social}>
                                <li>
                                    <a
                                        href={`mailto:?subject=${document.title}&body=${window.location.href}`}
                                    >
                                        <Mail />
                                        Delen via mail
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`whatsapp://send?text=${window.location.href}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Whatsapp />
                                        Delen via WhatsApp
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={window.location.href}
                                        onClick={this.copyToClipboard}
                                    >
                                        <Link />
                                        {copiedLink
                                            ? "Link gekopieerd!"
                                            : "Link delen"}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Facebook />
                                        Delen op Facebook
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${document.title}&source=CareerRebels`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LinkedIn />
                                        Delen op LinkedIn
                                    </a>
                                </li>
                            </ul>
                        )}
                    </CSSTransition>
                    {quote && (
                        <div
                            className={styles.quote}
                            dangerouslySetInnerHTML={{ __html: `‘’${quote}’’` }}
                        />
                    )}
                </aside>
                <article className={styles.content}>
                    <div
                        className={styles.intro}
                        dangerouslySetInnerHTML={{ __html: intro }}
                    />
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </article>
            </div>
        )
    }
}

export default CaseDetail
