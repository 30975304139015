import React from "react"
import styles from "./styles.module.scss"

import Button from "../button"
import ArrowRight from "../../icons/arrow-right"
import Dropdown from "../button/dropdown"

export default function FloatingButtons({ toggle }) {
    return (
        <div className={styles.floating}>
            <Dropdown name="Delen" />
            <Button
                to="/"
                className={[
                    "white",
                    "space-between",
                    styles["floating--button"],
                ]}
                onClick={toggle}
            >
                Solliciteer <ArrowRight />
            </Button>
            <Button
                to="/recruiters"
                className={[
                    "blue",
                    "space-between",
                    styles["floating--button"],
                ]}
            >
                Kies je recruiter
            </Button>
        </div>
    )
}
