import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"

import Loading from "../loading"
import Questions from "../questions"

const FaqTabs = (props) => {
    const [state, setState] = useState({
        response: null,
        loading: true,
        active: 0,
    })

    const changeActive = (index) => {
        setState({
            ...state,
            active: index,
        })
    }

    const { active } = state

    const getActive = (id) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/pages/customer-service/questions?index=${id}`,
        )
            .then((response) => response.json())
            .then((response) => {
                setState({
                    ...state,
                    response: response,
                    loading: false,
                })
            })
            .catch((error) => {
                setState({
                    ...state,
                    response: null,
                    loading: false,
                })
            })
    }

    useEffect(() => {
        getActive(active)
        // eslint-disable-next-line
    }, [active])

    return (
        <section>
            <div className={styles.content}>
                <ul className={styles.list}>
                    {props.categories.map((category, i) => (
                        <li
                            key={i}
                            className={state.active === i ? "is-active" : null}
                            onClick={() => changeActive(i)}
                        >
                            {category}
                        </li>
                    ))}
                </ul>
                <div className={styles.description}>
                    {state.loading ? (
                        <Loading />
                    ) : (
                        <Questions items={state.response} />
                    )}
                </div>
            </div>
        </section>
    )
}

export default FaqTabs
