import { useParams } from "react-router-dom"
import Pagination from "../pagination"
import styles from "./styles.module.scss"
import VacaturesOverviewItem from "./item"

export default function VacaturesOverview({ vacancies }) {
    const { page } = useParams()
    const { items, total, perPage } = vacancies
    const currentPage = page === undefined ? 0 : page - 1

    return (
        <section className="section section--yellow-light">
            <div className="wrapper">
                {vacancies ? (
                    <>
                        <h2 className={styles.title}>
                            {total} perfecte matches gevonden!
                        </h2>
                        <div className={styles.grid}>
                            {Object.values(items[currentPage]).map((item) => (
                                <VacaturesOverviewItem
                                    key={item.id}
                                    item={item}
                                />
                            ))}
                        </div>
                        {total > 12 && (
                            <Pagination
                                total={total}
                                perPage={perPage}
                                currentPage={
                                    page === undefined ||
                                    window.location.pathname ===
                                        "/vacatures/" ||
                                    window.location.pathname === "/vacatures"
                                        ? 1
                                        : parseInt(page)
                                }
                                base="/vacatures/pagina"
                                queryString=""
                            />
                        )}
                    </>
                ) : (
                    <h2 className={styles.title}>
                        Helaas, geen matches gevonden
                    </h2>
                )}
            </div>
        </section>
    )
}
