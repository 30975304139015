import React from "react"
import styles from "./styles.module.scss"

import Button from "../button"
import WeGotItAll from "../../icons/we-got-it-all"
import PurpleArrow from "../../icons/arrows-purple"
import ArrowRight from "../../icons/arrow-right"

const ContentImage = ({ content, title, logo, button }) => {
    return (
        <section className={styles.text}>
            <PurpleArrow className={styles.arrows} />
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <figure className={styles.figure}>
                    <WeGotItAll />
                </figure>
                <div className={styles.content}>
                    {logo && <div className={styles.logo} />}
                    <h1
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <article
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                    {button.text && (
                        <Button className={["blue"]} to={button.link}>
                            {button.text}
                            <ArrowRight />
                        </Button>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ContentImage
