import React from "react"

const Facebook = (props) => (
    <svg
        {...props}
        width="102px"
        height="102px"
        viewBox="0 0 102 102"
        version="1.1"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fillRule="nonzero" fill="currentColor">
                <path d="M0,0 L0,102 L102,102 L102,0 L0,0 Z M90,90 L51,90 L51,63.49 L65.91,63.49 L65.91,51.49 L51,51.49 L51,38.6 L65.91,38.6 L65.91,26.6 L39,26.6 L39,51.49 L30.09,51.49 L30.09,63.49 L39,63.49 L39,90 L12,90 L12,12 L90,12 L90,90 Z" />
            </g>
        </g>
    </svg>
)

export default Facebook
