import React from "react"
import { useFetch } from "use-http"
import styles from "./styles.module.scss"

import Location from "../location"
import PageLoading from "../page-loading"
import FooterNavigation from "../footer-navigation"
import FooterCopyright from "../footer-copyright"

import Facebook from "../../icons/facebook"
import Instagram from "../../icons/instagram"
import LinkedIn from "../../icons/linkedin"
import YouTube from "../../icons/youtube"
import Link from "../../icons/link"
import LogoNL from "../../icons/logo-nl"
import A from "../../images/kiwa-iso-9001.jpg"
import { classnames } from "../../helpers/classnames"

const Footer = () => {
    const { loading, data } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/footer`,
        {},
        [],
    )

    return loading ? (
        <PageLoading />
    ) : (
        <>
            <footer id="footer" className={styles.footer}>
                <div className={classnames(styles.wrapper, "wrapper")}>
                    {data && (
                        <>
                            <div className={styles["logo-mobile"]}>
                                <LogoNL />
                            </div>
                            <div
                                className={classnames(
                                    styles["footer-column"],
                                    styles["footer-column--left"],
                                )}
                            >
                                <div className={styles.brand}>
                                    <div className={styles.logo}>
                                        <LogoNL />
                                    </div>
                                    <b className={styles["social-title"]}>
                                        Let's get social?
                                    </b>
                                    <ul className={styles.social}>
                                        {data.social.map((item, i) => (
                                            <li key={i}>
                                                <a
                                                    aria-label={item.label}
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.label ===
                                                    "Facebook" ? (
                                                        <Facebook />
                                                    ) : item.label ===
                                                      "Instagram" ? (
                                                        <Instagram />
                                                    ) : item.label ===
                                                      "LinkedIn" ? (
                                                        <LinkedIn />
                                                    ) : item.label ===
                                                      "YouTube" ? (
                                                        <YouTube />
                                                    ) : (
                                                        <Link />
                                                    )}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    <img
                                        className={styles.kiwa}
                                        src={A}
                                        alt="ISO 9001"
                                        style={{
                                            width: "auto",
                                            height: "60px",
                                        }}
                                    />
                                </div>
                                <FooterNavigation items={data.navigation} />
                            </div>
                            <div
                                className={classnames(
                                    styles["footer-column"],
                                    styles["footer-column--locations"],
                                )}
                            >
                                {data.locations.map((location, i) => (
                                    <Location
                                        key={i}
                                        type="footer"
                                        location={location}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </footer>
            {data && <FooterCopyright items={data.copyrightNavigation} />}
        </>
    )
}

export default Footer
