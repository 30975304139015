import React from "react"
import { NavLink } from "react-router-dom"
import { useFetch } from "use-http"

import styles from "./styles.module.scss"
import Button from "../button"
import Loading from "../loading"
import ArrowRight from "../../icons/arrow-right"

const HeaderMenuItems = (props) => {
    const { data: response, loading } = useFetch(
        `${process.env.REACT_APP_API_URL}/pages/navigation/vertical`,
        {},
        [],
    )

    return loading ? (
        <Loading />
    ) : (
        <nav className={styles.navigation}>
            {response &&
                response.map((item, i) => {
                    return item.button ? (
                        <Button
                            className={["blue"]}
                            to={item.slug}
                            simple
                            key={i}
                        >
                            {item.label} <ArrowRight />
                        </Button>
                    ) : (
                        <NavLink
                            key={i}
                            exact
                            activeClassName={styles.active}
                            className={
                                item.mobile ? styles["show-on-mobile"] : null
                            }
                            to={`${item.slug}`}
                            onClick={props.close}
                        >
                            {item.title} <ArrowRight />
                        </NavLink>
                    )
                })}
            <a
                href="https://careerrebels-kk.kentro.nl/KentroKonnekt/Login.aspx"
                rel="noopener noreferrer"
                target="_blank"
            >
                Mijn uren
            </a>
        </nav>
    )
}

export default HeaderMenuItems
