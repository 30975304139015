import React, { useState } from "react"
import styles from "./styles.module.scss"

import Author from "../author"
import CheckCircle from "../../icons/check-circle"

const Personal = ({ recruiter }) => {
    const [active, setActive] = useState(false)

    const isSmall = window.innerWidth < 768

    const readMore = () => {
        setActive(!active)
    }

    const over = active
        ? recruiter.persoonlijk.over_mij
        : recruiter.persoonlijk.over_mij.slice(0, 300)

    const shorten =
        over.substr(0, Math.min(over.length, over.lastIndexOf(" "))) + "..."

    return (
        <div className="wrapper">
            <h2 className={styles["section--title"]}>
                Aangenaam, {recruiter.name}
            </h2>
            <div className={styles.panel}>
                <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: isSmall
                            ? active
                                ? over
                                : shorten
                            : recruiter.persoonlijk.over_mij,
                    }}
                />
                {isSmall && !active && (
                    <span className={styles.readmore} onClick={readMore}>
                        ...meer
                    </span>
                )}
            </div>
            <div className={styles.hobbys}>
                <div className="columns">
                    <div className="column-6">
                        <h2 className={styles["section--title"]}>
                            Ja, ik heb ook hobby's!
                        </h2>
                        <div className={styles.list}>
                            {recruiter.persoonlijk.hobbys.map((item, i) => (
                                <div key={i} className={styles["list--item"]}>
                                    <CheckCircle fill="#714d99" check="#fff" />
                                    <span>{item.hobby}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="column-6">
                        <Author recruiter={recruiter} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Personal
