import styles from "./styles.module.scss"

export default function ContentVideoBlock({ content, video }) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.video}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
                <div className={styles.frame}>
                    <iframe
                        width="600px"
                        height="338px"
                        src={video}
                        title="Career Rebels"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </div>
    )
}
