import styles from "../detail.module.scss"
import Mail from "../../../icons/mail"
import Telephone from "../../../icons/telephone"
import Whatsapp from "../../../icons/whatsapp"

export default function VacatureRecruiter({ recruiter }) {
    return (
        <div className={styles.recruiter}>
            <div className={styles.recruiterDetails}>
                <h4 className={styles.recruiterTitle}>
                    Heb je nog vragen?
                    <br />
                    Stel ze aan {recruiter.first_name}
                </h4>
                <div className="flex gap-1 mt-4 w-max">
                    <Mail />
                    <a href={`mailto:${recruiter.emailaddress}`}>
                        {recruiter.emailaddress}
                    </a>
                </div>
                <div className="flex gap-1 mt-4">
                    <Telephone />
                    <a href={`tel:${recruiter.phonenumber}`}>
                        {recruiter.phonenumber}
                    </a>
                </div>
                <div className="flex gap-1 mt-4">
                    <Whatsapp />
                    <a href={`https://wa.me/31857734495`}>+31 85 773 44 95</a>
                </div>
            </div>
            <div>
                <img src={recruiter.image} srcSet={recruiter.srcSet} alt="" />
            </div>
        </div>
    )
}
