import React from "react"
import { NavLink } from "react-router-dom"
import styles from "./styles.module.scss"

const FooterCopyright = ({ items }) => {
    return (
        <footer className={styles.copyright}>
            <div className={[styles.wrapper, "wrapper"].join(" ")}>
                <span>
                    &copy; {new Date().getFullYear()} CareerRebels BV. All
                    rights reserved.
                </span>
                {items && (
                    <nav className={styles.navigation}>
                        {items.map((item) => {
                            return item.slug.includes("http") ? (
                                <a
                                    key={item.slug}
                                    href={item.slug}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {item.label}
                                </a>
                            ) : (
                                <NavLink key={item.slug} to={item.slug}>
                                    {item.label}
                                </NavLink>
                            )
                        })}
                    </nav>
                )}
            </div>
        </footer>
    )
}

export default FooterCopyright
